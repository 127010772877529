import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabela from "./table";
import Create from "./create/index";
import Show from "./show";
import Introduction from "./introduction";

import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: "80px",
  },
  introduction: {
    fontSize: "30px",
    padding: "20px",
    fontWeight: "bold",
    color: "#57acb0",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
  },
  btn: {
    color: "white",
    position: "absolute",
    right: 20,
  },
}));

function Courses(props) {
  const classes = useStyles();
  const { translator, user } = props;

  const viewNow = sessionStorage.getItem("view")
    ? sessionStorage.getItem("view")
    : sessionStorage.setItem("view", 0);

  const [view, setView] = useState(parseInt(viewNow));

  const [reloading, setReloading] = useState('')
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState();

  const onChangeView = () => {
    if (view !== 0) {
      setView(0);
      sessionStorage.removeItem("view");
      sessionStorage.removeItem("course");
      sessionStorage.setItem("view", 0);
    } else {
      setView(1);
      sessionStorage.removeItem("view");
      sessionStorage.setItem("view", 1);
    }
  };
 
  useEffect(() => {
    async function loadCourses () {
      const response = await fetch(
        `${process.env.REACT_APP_API}/courses/${user._id}`,
        {
           method: "GET",
          headers: {'Access-Control-Allow-Origin': '*'},
       }
      );
      const data = await response.json();
      setCourses(data);
    };
    loadCourses()
  }, [view, reloading, user]);

 

  useEffect(() => {
    if (viewNow === undefined) {
      setView(0);
      sessionStorage.removeItem("view");
      sessionStorage.setItem("view", 0);
    }
  }, [viewNow]);

  return (
    <div className={classes.body}>
      <span className={classes.introduction}>
        {view === 0 && translator.framework.introduction}
        {view === 1 && translator.framework.createTitle}
        {view === 2 && translator.framework.showTitle}
      </span>
      <Button
        variant="contained"
        color={
          view === 0
            ? translator.groupBtn.primary
            : view === 1
            ? translator.groupBtn.secondary
            : view === 2
            ? translator.groupBtn.inherit
            : translator.groupBtn.primary
        }
        size="small"
        className={classes.btn}
        onClick={() => {
          onChangeView();
        }}
        startIcon={
          view === 0 ? (
            <SaveIcon />
          ) : view === 1 ? (
            <DeleteForeverIcon />
          ) : view === 2 ? (
            <KeyboardBackspaceIcon />
          ) : (
            <SaveIcon />
          )
        }
      >
        {view === 0 && translator.framework.btnCreate}
        {view === 1 && translator.framework.btnDiscard}
        {view === 2 && translator.framework.btnReturn}
      </Button>
      {view === 0 && (
        <>
          <Introduction translator={translator} />{" "}
          <Tabela
            translator={translator}
            user={user}
            setView={setView}
            setCourse={setCourse}
            courses={courses}
            setReloading={setReloading}
          />
        </>
      )}
      {view === 1 && <Create translator={translator} user={user} />}
      {view === 2 && (
        <Show
          translator={translator}
          user={user}
          courses={course}
          setView={setView}
        />
      )}
    </div>
  );
}

export default Courses;
