import React from "react";
import {
  makeStyles,
  FormControl,
  Grid,
  Paper,
  InputLabel,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    color: "#57acb0",
    flexGrow: 2,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitle: {
    textAlign: "justify",
  },
  share: {
    margin: "30px 0px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  media: {
    width: "200",
    maxHeight: 400,
    margin: "30px 0px",
  },
}));

function App(props) {
  const classes = useStyles();
  const {
    data,
    setResponse1,
    setResponse2,
    setResponse3,
    setResponse4,
    setResponse5,
    setResponse6,
    setResponse7,
    setResponse8,
    setResponse9,
    setResponse10,
    setResponse11,
    setResponse12,
    setResponse13,
    setResponse14,
    setResponse15,
    setResponse16,
    setResponse17,
    setResponse18,
    setResponse19,
    setResponse20,
    setResponse21,
    setResponse22,
    setResponse23,
    setResponse24,
    setResponse25,
    setResponse26,
    setResponse27,
  } = props;
  const { stages } = JSON.parse(data);

  return (
    <div className={classes.body}>
      <h1 className={classes.title}>Create the Course Map</h1>
      <span className={classes.subTitle}>
        How can you build the key learning events and instruction around the
        twelve stages of the Heroine’s Learning journey?
      </span>
      <hr className={classes.share} />
      <FormControl>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE I - EMPOWERMENT - Learning Heroine’s World: Progress -
                Stage 1, Step 1
              </h3>
              <span>
                What happens? - Her initial world is full of anguish, doubt and
                lack of confidence. She has several daily activities that
                include studies, family and leisure. She needs to improve her
                STEM skills, she wants to be part of a group. She has doubts
                about STEM career, but believes that the odds are on her
                side(Learner).
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[0].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta curta
                </InputLabel>
                <Input onChange={(event) => setResponse1(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <span>
                Dissemination of the course on the networks and send a message
                with a invitation for new learners
              </span>
              <p />
              <h5>Who does this task?</h5>
              <p />
              <FormControl fullWidth>
                <RadioGroup onChange={(event) => setResponse2(event.target.value)} aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="Tutor"
                    control={<Radio />}
                    label="Tutor"
                  />
                  <FormControlLabel
                    value="Teacher"
                    control={<Radio />}
                    label="Teacher"
                  />
                  <FormControlLabel
                    value="Mother Goddess"
                    control={<Radio />}
                    label="Mother Goddess"
                  />
                  <FormControlLabel
                    value="Allies"
                    control={<Radio />}
                    label="Allies"
                  />
                  <FormControlLabel
                    value="Other way"
                    control={<Radio />}
                    label="Other way"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>
                The Learner must watch the first video, receive link to register
                for the course and link to rules document.
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Introduction Video - step 1 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE I - EMPOWERMENT - Malala's Call: Progress - Stage 1, Step
                2
              </h3>
              <span>
                What happens? Having fulfilled the agreement and the
                promise(Learner), in this step, receives a motivational and
                symbolic message from (Tutor; Teacher; Allies).This message must
                arrive digitally, through audio, video, text or other media and
                briefly present the challenges that will be overcome during the
                course, practical ways of applying the subject’s contents in
                real life and a brief presentation of the technologies and tools
                that will be used during the course.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[1].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse3(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <span>
                Sends a welcome message explaining how the entire learning
                process works, team involved, evaluation mechanism, final
                project and the content
              </span>
              <p />
              <h5>Who does this task?</h5>
              <p />
              <FormControl fullWidth>
                <RadioGroup onChange={(event) => setResponse4(event.target.value)} aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="Tutor"
                    control={<Radio />}
                    label="Tutor"
                  />
                  <FormControlLabel
                    value="Teacher"
                    control={<Radio />}
                    label="Teacher"
                  />
                  <FormControlLabel
                    value="Mother Goddess"
                    control={<Radio />}
                    label="Mother Goddess"
                  />
                  <FormControlLabel
                    value="Allies"
                    control={<Radio />}
                    label="Allies"
                  />
                  <FormControlLabel
                    value="Other way"
                    control={<Radio />}
                    label="Other way"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <span>
                Provides materials (guide, exercises, tips) and journey map
              </span>
              <p />
              <h5>Who does this task?</h5>
              <p />
              <FormControl fullWidth>
                <RadioGroup onChange={(event) => setResponse5(event.target.value)} aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="Tutor"
                    control={<Radio />}
                    label="Tutor"
                  />
                  <FormControlLabel
                    value="Teacher"
                    control={<Radio />}
                    label="Teacher"
                  />
                  <FormControlLabel
                    value="Mother Goddess"
                    control={<Radio />}
                    label="Mother Goddess"
                  />
                  <FormControlLabel
                    value="Allies"
                    control={<Radio />}
                    label="Allies"
                  />
                  <FormControlLabel
                    value="Other way"
                    control={<Radio />}
                    label="Other way"
                  />
                </RadioGroup>
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                A representative person from the STEM area can participate with
                some activities like first class, recorded video, virtual
                meeting, email or message(Allies)
              </h3>
              <span>
                Does this course have any activities of this type? Please write
                below what activities will be available at this step
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse6(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>
                (Learner) must watch second video, read welcome message,
                instructions and participate the others activities if the course
                presents anything else.
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Malala's Video - step 2 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE I - EMPOWERMENT - External and Internal Aid: Progress -
                Stage 1, Step 3
              </h3>
              <span>
                What happens? It is time to receive external aid(Learner) from
                specialized people (Tutor; Teacher; Allies) as well as other
                types of help that offer all the support needed for the journey.
                So if (Learner) happens to be afraid of male figures judging
                your performance, if (Learner) face various cultural barriers
                and or rejections in the job market, this is really chance to
                change your conception. In order to end this step with other
                examples of female success in the STEM areas, (Learner) can
                receive content related to successful women in these fields.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[2].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse7(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                Depending on the course (Tutor; Teacher; Allies), makes
                available a personality test or similar to (Learner) which
                defines the type of student or persona.
              </h3>
              <span>
                Does this course have any activities of this type? Please write
                below what type of test the course offers
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse8(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>
                (Learner) must watch third video and perform the test.
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>External and Internal Aid video - step 3 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE I - EMPOWERMENT -Facing a Storm: Progress - Stage 1, Step
                4
              </h3>
              <span>
                What happens? - The character who helps the students (Athena),
                goddess of wisdom, appears. Athena can be personified within
                course by robots, tutors or specialists. Now (Learner) needs to
                dedicate herself to studies, problems with time management
                procrastination and difficulties may arise. Describe below how
                Athena relates to students, if she is a character
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[3].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse9(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                In this step(Learner), will get to know the first videos,
                podcasts, tutorials, manuals, and other basic content regarding
                the course(Tutor; Teacher; Allies).
              </h3>
              <span>
                Please write down the list of videos containing your description
                and time. Do not forget to also describe any other learning
                activities that will be provided in this step
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse10(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                Will students have access to new platforms and technological
                environments?
              </h3>
              <span>
                Write down the list of environments and platforms that will be
                available in this step
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse11(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 15h</h3>
              <span>
                This is definitely the time for greater effort, so (Learner)
                should overcome this storm with all the dedication of a true
                heroine. (Learner) must watch fourth video, dedicated to
                studying all the content presented and search other content and
                activities on the internet.
              </span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Facing a Storming video - step 4 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE I - EMPOWERMENT -STEM Learners can say no: Progress -
                Stage 1, Step 5
              </h3>
              <span>
                What happens? - Now(Learner), probably lost your fear about
                contents of the exact areas, gained confidence through your
                activities and projects, and it may be possible that have
                comments on forums, specific collaboration tasks to finally say,
                “STEM learners can say no”. When someone tells you that you have
                no ability to program, perform calculations, analyze data, or
                design tools and applications, you say ”No, i can do it!”.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[4].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse12(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                External help is available to students (Tutor; Teacher; Allies).
                This help can be with human participation or informed through
                tips, suggestions, videos or previously programmed to occur
                automatically for (Learner); May arise videos or interviews with
                girls from companies or initiatives(Tutor; Teacher; Allies), to
                (Learner)get to know the technology work environments (virtual
                or face-to-face), and examples of applications about related
                course.
              </h3>
              <span>
                Please write down the list helps containing your description and
                time. Do not forget to also describe any other learning
                activities that will be provided in this step
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse13(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 2h</h3>
              <span>(Learner) must watch fifth video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>STEM Learners can say no video - step 5 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE II - GROWTH -The Light of Knowledge: Progress - Stage 2,
                Step 6
              </h3>
              <span>
                What happens? - Considered as one of the most important stages,
                here (Learner)need to understand what found in this new world,
                yes, (Learner) found a new world. As of right now, programming
                codes, mathematical formulas, and theories that (Learner)have so
                far, will allow (Learner) to create new projects and activities.
                In this new world, in the area of Science, Technology,
                Engineering e Mathematics, there are other students, friends,
                work presentations, workshops, and new opportunities(Allies).
                here (Learner)need to understand what found in this new world,
                yes, (Learner) found a new world. As of right now, programming
                codes, mathematical formulas, and theories that (Learner)have so
                far, will allow (Learner) to create new projects and activities.
                In this new world, in the area of Science, Technology,
                Engineering e Mathematics, there are other students, friends,
                work presentations, workshops, and new opportunities(Allies).
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[5].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse14(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                In this step, content related to the different areas of STEM can
                be presented to (Learner) by (Tutor; Teacher; Allies) as
                practical activities. For example, data manipulation,
                statistics, hands-on activity, presentations of real
                applications. Assessments made by (Tutor; Teacher) or previously
                programmed by the course, can happen.
              </h3>
              <span>
                Please write below the list practical activities, your
                description and time. Do not forget to also describe any test or
                assessment provided by the course that will be provided in this
                step
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse15(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 3h</h3>
              <span>(Learner) must watch sixth video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>The Light of Knowledge video - step 6 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE II - GROWTH - The Meeting with the Lady of Keys: Progress
                - Stage 2, Step 7
              </h3>
              <span>
                What happens? - This step will require (Learner) to put into
                practice everything they learned so far. (Learner)ran through
                the basics of matter and feel strong enough to continue your
                studies. (Learner) also commit mathematical formulas from data
                queries or other important theories in the fields of STEM.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[6].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse16(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                There may be presentations by (Learner), group tasks (Learner;
                Allies) or projects can occur. If the course requires building
                projects, now is the time to development a real application.
              </h3>
              <span>
                Please write below the activities, your description and time.
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse17(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 5h</h3>
              <span>(Learner) must watch seventh video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>The Meeting with the Lady of Keys video - step 7 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE II - GROWTH - The Encryption of codes: Progress - Stage 2,
                Step 8
              </h3>
              <span>
                What happens? - So far (Learner) who have learned a lot, are
                ready. What was the last thing that (Learner) get? (Learner)
                acquired self-confidence, self-regulation inthe learning
                process, (Learner) also lost the fear of programming or
                performing math-ematical formulas, for example, this way several
                applications, simulations, exercises,developed by (Learner) are
                available on the education platforms of the course, or even in
                public repositories, social networks, and (Learner) personal
                computers. The time for (Learner) big challenge is coming,
                (Learner) needs to get ready, get organized and prepared for the
                next step.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[7].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse18(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                Simulations, exercises, evaluations and reflections on content
                occur at this step by (Learner; Allies). In this step, (Learner)
                can share their projects on socialnetworks, through videos or
                code repository. The final project development it mayhappens by
                (Learner). Hands On from (Tutor; Teacher; Allies) can also
                happens to(Learner) develop your solutions.
              </h3>
              <span>
                Please write below the activities, your description and time.
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input  onChange={(event) => setResponse19(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 5h</h3>
              <span>(Learner) must watch eighth video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>The Encryption of codes video - step 8 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE II - GROWTH - Numbers are passwords: Progress - Stage 2,
                Step 9
              </h3>
              <span>
                What happens? - (Learner) are about to face your first big
                challenge or project,but (Learner) already have some examples
                that will facilitate the final clash of deliveries in this
                course. So (Learner) feel confident because they are prepared,
                they need to deliver your project, carry out a test, carry out
                an assessment or practical work, inorder to obtain the
                evaluation of (Tutor, Teacher) and the community around
                them.Let’s go ahead, (Learner) can overcome this great
                challenge.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[8].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input  onChange={(event) => setResponse20(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                (Learner) need to perform project presentations, pitches,
                assignments, exercises and final assessments. There may be
                evaluations by the students themselves.
              </h3>
              <span>
                Please write below the activities, your description and time.
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse21(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 2h</h3>
              <span>(Learner) must watch ninth video</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Numbers are passwords video - step 9 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE II - GROWTH - Self-Regulated Learning: Progress - Stage 2,
                Step 10
              </h3>
              <span>
                What happens? - (Learner) who came face to face with the great
                challenges and enemies, now have the results of all efforts. It
                is time to have access to final scores and evaluations of the
                course. Also, time to be sure, that with all their practice and
                new skills acquired (Learner) can continue learning STEM.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[9].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse22(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                (Learner) receives reports, results of final evaluations and
                final instructions consolidated in 10 main tips for (Learner) to
                continue their studies in a satisfactory manner.
              </h3>
              <span>
                Please write below what reports, results or final evaluations
                the course have and how is show to students. Don't forget to
                send the 10 main tips building by Heroines Learning Journey
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse23(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>(Learner) must watch tenth video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Self-Regulated Learningvideo - step 10 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE III - RETURN - Reboot The System: Progress - Stage 3, Step
                11
              </h3>
              <span>
                What happens? - In this step (Learner) get to know how they can
                become a true heroine. This step offers to (Learner) the
                possibility of earning (Learner Heroine)status.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[10].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse24(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                This course has the option to generate a code for participating
                in a multi-platform instant messaging application, text
                messages, images, videos and documents, such as telegram,
                discord? participation in these applications is intended to make
                the student keep studying in STEM with other students who have
                the same interest, is specific to each course and can only be
                used for subjects related to the topic, as well as providing
                help, tips and promoting the relationship between students.
              </h3>
              <span>
                If this course has this option, please describe how the group
                will be organized, which application will be used and which
                roles will participate
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse25(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>(Learner) must watch eleventh video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Reboot The System video - step 11 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                STAGE III - RETURN -Becoming a STEM Heroine: Progress - Stage 3,
                Step 12
              </h3>
              <span>
                What happens? - So, what are the latest challenges? How did
                (Learner) transform? Where will other girls share their
                knowledge? What will happens to (Learner)now? How was the
                individual feedback they had? Only true (Learner Heroine) are
                able to accomplish this step.
              </span>
              <p />
              <center>
                <img
                  className={classes.media}
                  src={stages[11].image}
                  alt="Img"
                />
              </center>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse26(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>
                As a result of this journey, the (Learner Heroine) receives a
                certificate or a final report that proves your bravery and
                experience. But for that to happens and to maintain their
                (Learner Heroine) status, they will still need to help
                another(Learner) out to start her studies by conducting a
                mentoring during the next course offered.
              </h3>
              <span>
                If this course has this option, please describe how is the
                certificate or report that students receive, as well as the
                invitation for Learner Heroine to join next edition of the
                course.
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setResponse27(event.target.value)} id="roles" name="stages-3-1" />
              </FormControl>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Maximum estimated duration of Learner activity: 1h</h3>
              <span>(Learner) must watch Twelfth video.</span>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <h3>Becoming a STEM Heroine video - step 12 of 12</h3>
              <p />
              <center>
                <img
                  className={classes.media}
                  src="../images/img_video.png"
                  alt="Img"
                />
              </center>
            </Paper>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
}

export default App;
