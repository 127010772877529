import React from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    textAlign: "center",
    marginTop: "2%",
    marginBottom: "2%",
  },
  objetivo: {
    width:'600px'
  },
}));

function Objetivo() {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <Container fixed>
        <img
          className={classes.objetivo}
          src="images/objetivo.png"
          alt=""
        />
      </Container>
    </div>
  );
}

export default Objetivo;
