import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    padding: "2%"
  },
  box:{
    margin: "0px 20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  img: {
      width: 300,
      padding:"0%  0%"
  },
  img1: {
    height:80,
    padding:"15%  0%"
},
}));

function Parceiros() {
  const classes = useStyles();
  return (
    <Box className={classes.body}>
      <Box className={classes.box}>
        <img className={classes.img} src="images/logo-coppe.png" alt="" />
      </Box>

      <Box className={classes.box}>
        <img className={classes.img} src="images/logo-dm.jpg" alt="" />
      </Box>
      
      <Box className={classes.box}>
        <img className={classes.img1}  src="images/logo-ludes.jpeg" alt="" />
      </Box>
    </Box>
  );
}

export default Parceiros;
