import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CardMedia } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  div: {
    color: "#af85bb",
    position: "relative",
  },
  textInterno: {
    position: "absolute",
    top: "50%",
    fontSize: 60,
    width: '100%',
    textAlign: 'center',
    fontFamily:
    "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace"
  },
}));

function Capa(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <div className={classes.div}>
      <CardMedia
        component="video"
        width="100%"
        image="https://journeys.macrosplan.com.br/azul.mp4"
        autoPlay
        loop
      />
      <span className={classes.textInterno}><strong>{translator.main.capa.title}</strong></span>
    </div>
  );
}

export default Capa;
