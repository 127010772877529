import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "90vh",
    paddingTop: "20vh",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 80,
    width: 80,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alert: {
    position: "fixed",
    width: 300,
    bottom: 120,
    right: 20,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

function SignIn(props) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [alert, setAlert] = useState();
  const [view, setView] = useState(0);

  const { translator } = props;

  useEffect(() => {
    if (alert === true) {
      setView(1);
    } else if (alert === false) {
      setView(2);
    }
  }, [alert]);

  useEffect(() => {
    if (view !== 0) {
      setTimeout(() => {
        setView(0);
      }, 4000);
    }
  }, [view]);

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangeSenha = (event) => {
    setSenha(event.target.value);
  };

  const save = (user) => {
    sessionStorage.setItem("currentUser", JSON.stringify(user));
  };

  const logar = async (event) => {
    event.preventDefault();
    setView(3);
    const url = `${process.env.REACT_APP_API}/user`;
    const type = "logar";
    const data = {
      email,
      senha,
      type,
    };
    await fetch(url, {
      method: "POST",
      headers: {'Access-Control-Allow-Origin': '*', 'content-type': 'aplication/json'},
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        save(data);
        window.location.href = "/courses";
      })
      .catch((error) => {
        setAlert(false);
      });
  };

  return (
    <main className={classes.body}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar src="/images/logo.png" className={classes.avatar} />
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={translator.login.email}
              name="email"
              autoComplete="email"
              value={email}
              onChange={handleChangeEmail}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={translator.login.password}
              type="password"
              id="password"
              value={senha}
              onChange={handleChangeSenha}
              autoComplete="current-password"
            />
            {view === 3 && (
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            )}
            {view !== 3 && (
              <>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={translator.login.remenber}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={logar}
                >
                  {translator.login.btn}
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      {translator.login.forgot}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {translator.login.create}
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </form>
        </div>
      </Container>
      {view === 2 && (
        <Alert
          severity="error"
          className={classes.alert}
          onClose={() => {
            setView(0);
          }}
        >
          <AlertTitle>Error</AlertTitle>
          {translator.message.errorUser}
        </Alert>
      )}
    </main>
  );
}

export default SignIn;
