import React from "react";
import {
  makeStyles,
  FormControl,
  Grid,
  Paper,
  InputLabel,
  Input,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
const useStyles = makeStyles((theme) => ({
  body: {
    color: "#57acb0",
    flexGrow: 2,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitle: {
    textAlign: "justify",
  },
  share: {
    margin: "30px 0px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  table: {
    width: "100%",
  },
}));

function App(props) {
  const classes = useStyles();
  const {
    setName,
    setEdicao,
    setPaper,
    setCouserDesigner,
    setLeaner,
    setLeanerHeroine,
    setTutor,
    setTeacher,
    setAllies,
    setMotherGoddess,
    setAthena,
  } = props;
  return (
    <div className={classes.body}>
      <h1 className={classes.title}>Framework for Creating a Course Map</h1>
      <span className={classes.subTitle}>
        Leveraging in the creation of learning experiences begins with
        establishing the roles of the various stakeholders involved in the
        journey. Beyond establishing your learner, who are the other characters
        you’d like to include in the experience? Instructions: Fill in the boxes
        for the archetypes that you would like to use.
      </span>
      <hr className={classes.share} />

      <FormControl>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>What is the name of the course?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta curto
                </InputLabel>
                <Input
                  onChange={(event) => setName(event.target.value)}
                  id="roles"
                  name="stages-1-2"
                />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>What is the year of the course?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta curto
                </InputLabel>
                <Input
                  onChange={(event) => setEdicao(event.target.value)}
                  id="roles"
                  name="stages-1-3"
                />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.paper}>
              <span>
                Cast Your Epic: Based on some of the established character
                archetypes in the heroic journeys, which of the following roles
                will you use? Note: Not all roles must be filled by people. Be
                creative with your interpretation of your roles, and leave ones
                blank that you don’t feel you need.
              </span>
              <p />
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Role</strong>{" "}
                      </TableCell>
                      <TableCell>
                        <strong>Role Description</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Course Designer
                      </TableCell>
                      <TableCell>
                        Responsible for defining which roles will be at play, as
                        well as completing the Framework for Creating a Couse
                        Map, drafting rules, tutorials and additional resources
                        specific to the course.{" "}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Learner
                      </TableCell>
                      <TableCell>
                        Driver of the narrative and leader of the story. The one
                        who accepts Malala's Call.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Learner Heroine
                      </TableCell>
                      <TableCell>
                        A learner who becomes a Learner Heroine for having
                        sucessfully completed the entire journey within a STEM
                        course. She will return to par-ticipate as a mentor or
                        an ally in an upcoming course in order to help other
                        learners.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Tutor
                      </TableCell>
                      <TableCell>
                        A tutor, is the person who providers assistance and/or
                        tutelage to students on a given subject and/or acquiring
                        new skills. Tutoring can take place in different
                        settings and ways.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Teacher
                      </TableCell>
                      <TableCell>
                        Teacher responsible for the course design, didatic
                        content, and also for the planning, implementation and
                        completion of the course.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Allies
                      </TableCell>
                      <TableCell>
                        Assit the heroine in different moments of her journey.
                        May be another heroine on her own personal quest,
                        experts in a certain subject, a Learner Heroine or other
                        kind of helper.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Mother Goddess
                      </TableCell>
                      <TableCell>
                        Announces the journey and set the epic into motion. Can
                        often be replaced be an action (such as an invitation or
                        announcement) as opposed to a person.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Athena
                      </TableCell>
                      <TableCell>
                        Assists the Learner in her journey. Provides key
                        knowledge for the heroine's success.
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta curta
                </InputLabel>
                <Input
                  onChange={(event) => setPaper(event.target.value)}
                  id="roles"
                  name="stages-1-4"
                />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>
                Course Designer: Who will play this role? What will they do?
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setCouserDesigner(event.target.value)} id="roles" name="stages-1-5" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>Learner: Who will play this role? What will they do?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setLeaner(event.target.value)} id="roles" name="stages-1-6" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>
                Learner Heroine: Who will play this role? What will they do?
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setLeanerHeroine(event.target.value)} id="roles" name="stages-1-7" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>Tutor: Who will play this role? What will they do?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setTutor(event.target.value)} id="roles" name="stages-1-8" />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>Teacher: Who will play this role? What will they do?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setTeacher(event.target.value)} id="roles" name="stages-1-9" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>Allies: Who will play this role? What will they do?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setAllies(event.target.value)} id="roles" name="stages-1-10" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>
                Mother Goddess: Who will play this role? What will they do?
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setMotherGoddess(event.target.value)} id="roles" name="stages-1-11" />
              </FormControl>
            </Paper>
            <p />
            <Paper className={classes.paper}>
              <span>Athena: Who will play this role? What will they do?</span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setAthena(event.target.value)} id="roles" name="stages-1-12" />
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
}

export default App;
