import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { scroller } from "react-scroll";
import {
  makeStyles,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Link,
} from "@material-ui/core";

import locale from "./utils/Locale";
import DataContext from "./utils/DataContext";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ReactAudioPlayer from "react-audio-player";
import Main from "./components/main";
import Frameworks from "./components/frameworks";
import Framework from "./components/courses";
import Login from "./components/login";
import Footer from "./components/footer";

const useStyles = makeStyles((theme) => ({
  bar: {
    // backgroundColor: '#af85bb',
    backgroundColor: "#721e99",
    margin: theme.spacing(0),
  },
  grow: {
    flexGrow: 1,
  },
  logo: {
    height: 50,
    width: 50,
  },
  ancora: {
    marginLeft: theme.spacing(3),
    fontWeight: "bold",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
    color: "#57acb0",
    fontSize: 15,
    "&:hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "gray",
    },
  },
  title: {
    flexGrow: 1,
  },
  file: {
    position: "fixed",
    right: "20px",
    bottom: "40px",
  },
  btnAction: {
    padding: "8px",
    borderRadius: "100px",
    border: "solid 2px",
    minWidth: 60,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
  },
  group: {
    position: "relative",
  },
  subAncora: {
    position: "absolute",
    top: 20,
    backgroundColor: "#721e99",
    width: 170,
    padding: "15px 0px",
    borderRadius: "5px",
  },
  ancoraLaguage: {
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
    color: "#57acb0",
    fontSize: 15,
    "&:hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "gray",
    },
  },
  subAncoraLaguage: {
    position: "absolute",
    top: 30,
    left: 14,
    backgroundColor: "#721e99",
    width: 100,
    padding: "10px 0px",
    borderRadius: "5px",
  },
  user: {
    marginLeft: "20px",
  },
  btnLogout: {
    color: "white",
    marginTop: 6,
    "&:hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "gray",
    },
  },
}));

function App() {
  const classes = useStyles();
  const user = sessionStorage.length
    ? JSON.parse(sessionStorage.getItem("currentUser"))
    : null;

  const [checked, setChecked] = useState(false);
  const [checkedLanguage, setCheckedLanguage] = useState(false);
  const [language, setLanguage] = useState(
    user
      ? user.idioma
      : sessionStorage.getItem("language")
      ? sessionStorage.getItem("language")
      : navigator.language
  );

  const translator = locale[language];

  useEffect(() => {
    document.title = translator.nav.title;
  }, [translator]);

  useEffect(() => {
    if (user) {
      const saveLanguage = async () => {
        const url = `${process.env.REACT_APP_API}/user/${user._id}`;
        const data = {
          idioma: language,
        };
        await fetch(url, {
          method: "POST",
          headers: {'Access-Control-Allow-Origin': '*', 'content-type': 'aplication/json'},
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((data) => {
            sessionStorage.removeItem("currentUser");
            sessionStorage.setItem("currentUser", JSON.stringify(data));
          })
          .catch((error) => {
            console.log(error);
          });
      };
      saveLanguage();
    } else {
      sessionStorage.setItem("language", language);
    }
  }, [language, user]);
  const handleChange = (name) => (event) => {
    setCheckedLanguage(false);
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  const handleChangeLanguage = (name) => (event) => {
    setChecked(false);
    setLanguage(event.target.textContent);
    if (checkedLanguage) {
      setCheckedLanguage(false);
    } else {
      setCheckedLanguage(true);
    }
  };

  const logout = () => {
    sessionStorage.removeItem("currentUser");
    sessionStorage.removeItem("view");
    window.location.href = "/login";
  };

  const positionAbout = () => {
    scroller.scrollTo("about", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const path = document.location.pathname;

  return (
    <DataContext.Provider value={language}>
      <Router>
        {path !== "/login" && (
          <header id="header">
            <AppBar position="fixed" className={classes.bar}>
              <ReactAudioPlayer
                className={classes.file}
                src="https://journeys.macrosplan.com.br/file.mp3"
                controls
                autoPlay
              />
              <Toolbar>
                <Link
                  href="/"
                  underline="none"
                  color="inherit"
                  className={classes.ancora}
                >
                  <Typography className={classes.title}>
                    <Avatar src="/images/logo.png" className={classes.logo} />
                  </Typography>
                </Link>
                <div className={classes.grow} />
                {checkedLanguage && (
                  <div className={classes.group}>
                    <p />
                    <div className={classes.subAncoraLaguage}>
                      {language !== "pt-BR" && (
                        <>
                          <Link
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            color="inherit"
                            underline="none"
                            onClick={handleChangeLanguage()}
                            className={classes.ancoraLaguage}
                          >
                            pt-BR
                          </Link>
                        </>
                      )}
                      {language !== "en-US" && (
                        <Link
                          aria-controls="fade-menu"
                          aria-haspopup="true"
                          color="inherit"
                          underline="none"
                          onClick={handleChangeLanguage()}
                          className={classes.ancoraLaguage}
                        >
                          en-US
                        </Link>
                      )}
                    </div>
                  </div>
                )}
                <Link
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  color="inherit"
                  underline="none"
                  className={classes.ancora}
                  onClick={handleChangeLanguage()}
                >
                  <div className={classes.btnAction}>{language}</div>
                </Link>
                {path === "/" && (
                  <Link
                    href="/login"
                    underline="none"
                    color="inherit"
                    className={classes.ancora}
                  >
                    {translator.nav.framework}
                  </Link>
                )}
                {path === "/courses" && (
                  <Link
                    href="/"
                    underline="none"
                    color="inherit"
                    className={classes.ancora}
                  >
                    {translator.nav.main}
                  </Link>
                )}
                {checked && (
                  <div className={classes.group}>
                    <p />
                    <div className={classes.subAncora}>
                      <Link
                        href="http://github.com/Heroic-journey/HJL-SC"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        GitHub
                      </Link>
                      <p />
                      <Link
                        href="http://heroicjourneys.life:5000/swagger/"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        API + Swagger
                      </Link>
                      <p />
                      <Link
                        href="https://solve.mit.edu/challenges/learning-for-girls-and-women/solutions/26276"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        Solve MIT
                      </Link>
                      <p />
                      <Link
                        href="https://bit.ly/3p3MKej"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        Video
                      </Link>
                    </div>
                  </div>
                )}
                <Link
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  color="inherit"
                  underline="none"
                  className={classes.ancora}
                  onClick={handleChange()}
                >
                  {translator.nav.resources}
                </Link>

                {path === "/" && (
                  <Link
                    onClick={positionAbout}
                    color="inherit"
                    underline="none"
                    className={classes.ancora}
                  >
                    {translator.nav.about}
                  </Link>
                )}
                {path === "/courses" && user && (
                  <Link
                    onClick={logout}
                    color="inherit"
                    underline="none"
                    title={translator.nav.logout}
                    className={classes.ancora}
                  >
                    <ExitToAppIcon className={classes.btnLogout} />
                  </Link>
                )}
                {path === "/courses" && user && (
                  <Typography className={classes.user}>
                    <Avatar src={user.image} className={classes.logo}></Avatar>
                  </Typography>
                )}
              </Toolbar>
            </AppBar>
          </header>
        )}

        {path === "/login" && (
          <header id="header">
            <AppBar position="fixed" className={classes.bar}>
              <Toolbar>
                {user && (
                  <Link
                    href="/"
                    underline="none"
                    color="inherit"
                    className={classes.ancora}
                  >
                    <Typography className={classes.title}>
                      <Avatar src="/images/logo.png" className={classes.logo} />
                    </Typography>
                  </Link>
                )}
                <div className={classes.grow} />
                {checkedLanguage && (
                  <div className={classes.group}>
                    <p />
                    <div className={classes.subAncoraLaguage}>
                      {language !== "pt-BR" && (
                        <>
                          <Link
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            color="inherit"
                            underline="none"
                            onClick={handleChangeLanguage()}
                            className={classes.ancoraLaguage}
                          >
                            pt-BR
                          </Link>
                        </>
                      )}
                      {language !== "en-US" && (
                        <Link
                          aria-controls="fade-menu"
                          aria-haspopup="true"
                          color="inherit"
                          underline="none"
                          onClick={handleChangeLanguage()}
                          className={classes.ancoraLaguage}
                        >
                          en-US
                        </Link>
                      )}
                    </div>
                  </div>
                )}
                <Link
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  color="inherit"
                  underline="none"
                  className={classes.ancora}
                  onClick={handleChangeLanguage()}
                >
                  <div className={classes.btnAction}>{language}</div>
                </Link>
                <Link
                  href="/"
                  underline="none"
                  color="inherit"
                  className={classes.ancora}
                >
                  {translator.nav.main}
                </Link>

                {checked && (
                  <div className={classes.group}>
                    <p />
                    <div className={classes.subAncora}>
                      <Link
                        href="http://github.com/Heroic-journey/HJL-SC"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        className={classes.ancora}
                      >
                        GitHub
                      </Link>
                      <p />
                      <Link
                        href="http://heroicjourneys.life:5000/swagger/"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        className={classes.ancora}
                      >
                        API + Swagger
                      </Link>
                      <p />
                      <Link
                        href="https://solve.mit.edu/challenges/learning-for-girls-and-women/solutions/26276"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        Solve MIT
                      </Link>
                      <p />
                      <Link
                        href="https://bit.ly/3p3MKej"
                        target="_blank"
                        underline="none"
                        color="inherit"
                        onClick={handleChange()}
                        className={classes.ancora}
                      >
                        Video
                      </Link>
                    </div>
                  </div>
                )}
                <Link
                  aria-controls="fade-menu"
                  aria-haspopup="true"
                  color="inherit"
                  underline="none"
                  className={classes.ancora}
                  onClick={handleChange()}
                >
                  {translator.nav.resources}
                </Link>
                {path === "/" && (
                  <Link
                    onClick={positionAbout}
                    color="inherit"
                    underline="none"
                    className={classes.ancora}
                  >
                    {translator.nav.about}
                  </Link>
                )}
                { user && (
                  <Link
                    onClick={logout}
                    color="inherit"
                    underline="none"
                    title={translator.nav.logout}
                    className={classes.ancora}
                  >
                    <ExitToAppIcon className={classes.btnLogout} />
                  </Link>
                )}
                {user && (
                  <Typography className={classes.user}>
                    <Avatar src={user.image} className={classes.logo}></Avatar>
                  </Typography>
                )}
              </Toolbar>
            </AppBar>
          </header>
        )}

        <Switch>
          <Route exact path="/">
            <Main />
          </Route>
          <Route path="/framework">
            <Frameworks />
          </Route>
          <Route path="/login">
            {user ? (
              <Redirect to="/courses" />
            ) : (
              <Login translator={translator} />
            )}
          </Route>
          <Route path="/courses">
            {!user ? (
              <Redirect to="/login" />
            ) : (
              <Framework translator={translator} user={user} />
            )}
          </Route>
        </Switch>
        <Footer />
      </Router>
    </DataContext.Provider>
  );
}

export default App;
