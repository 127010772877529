import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

import Modal from "../modal/ModalConfirme";

const useStyles = makeStyles((theme) => ({
  body: {
    height: "100vh",
    paddingTop: "40px",
  },
  tableAcoes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
  },
  groupBtn: {},
  btn: {
    padding: 5,
    "&:nth-child(1)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "DarkGreen",
    },
    "&:nth-child(2)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "DarkGoldenRod",
    },
    "&:nth-child(3)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "DarkRed",
    },
  },
  title: {
    fontSize: "30px",
    padding: "20px",
    fontWeight: "bold",
    color: "#57acb0",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    verticalAlign: "center",
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

function Tabela(props) {
  const classes = useStyles();
  const { translator, user, setView, setCourse, courses, setReloading } = props;

  

  return (
    <div className={classes.body}>
      <span className={classes.title}>{translator.framework.title}</span>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">
                {translator.framework.table.name}
              </TableCell>
              <TableCell align="center">
                {translator.framework.table.anoEdicao}
              </TableCell>
              <TableCell align="center">
                {translator.framework.table.dtCadastro}
              </TableCell>
              <TableCell align="center">
                {translator.framework.table.etapaAtual}
              </TableCell>
              <TableCell align="center">
                {translator.framework.table.acoes}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course, index) => (
              <Row
                key={index}
                courses={course}
                historial={course.historial}
                translator={translator}
                user={user}
                setView={setView}
                setCourse={setCourse}
                setReloading={setReloading}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function Row(props) {
  const { courses, historial, translator, user, setView, setCourse, setReloading} = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const convertDate = (data) => {
    return new Date(parseInt(data)).toLocaleString();
  };

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleShowCourse = (course) => {
    setCourse(course)
    setView(2);
    sessionStorage.removeItem("view");
    sessionStorage.setItem("view", 2);
  };

  const handleDeleteCourse = async () => {
    setLoading(true);
    const data = {
      courseId: courses._id,
      type: "DELETE",
    };
    async function fetchData() {
      await fetch(`${process.env.REACT_APP_API}/courses/${user._id}`, {
        method: "POST",
        headers: {'Access-Control-Allow-Origin': '*', 'content-type': 'aplication/json'},
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          setReloading(courses._id)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
    setOpenModal(false)
  };

  const classes = useStyles();
  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{courses.response5}</TableCell>
        <TableCell align="center">{courses.response6}</TableCell>
        <TableCell align="center">{convertDate(courses.createdAt)}</TableCell>
        <TableCell align="center">{courses.stage}</TableCell>
        <TableCell className={classes.tableAcoes}>
          {loading && <CircularProgress color="secondary" />}
          {!loading && (
            <div className={classes.groupBtn}>
              <Tooltip
                title={<span>{translator.framework.table.tootipOpen}</span>}
              >
                <FolderOpenIcon
                  className={classes.btn}
                  onClick={() => {
                    handleShowCourse(courses);
                  }}
                />
              </Tooltip>
              <Tooltip
                title={<span>{translator.framework.table.tootipRemove}</span>}
              >
                <HighlightOffIcon
                  className={classes.btn}
                  onClick={handleClickOpen}
                />
              </Tooltip>
            </div>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {translator.framework.table.history}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translator.framework.table.indice}
                    </TableCell>
                    <TableCell align="center">
                      {translator.framework.table.dtUpdate}
                    </TableCell>
                    <TableCell align="center">
                      {translator.framework.table.descricao}
                    </TableCell>
                    <TableCell align="center">
                      {translator.framework.table.etapa}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historial.map((history, index) => (
                    <TableRow key={index + 1}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">
                        {convertDate(history.updatedAt)}
                      </TableCell>
                      <TableCell align="center">{history.descricao}</TableCell>
                      <TableCell align="center">{history.stage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal
        open={openModal}
        courses={courses}
        translator={translator}
        setOpen={setOpenModal}
        handleDeleteCourse={handleDeleteCourse}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default Tabela;
