import React from "react";
import {
  FormControl,
  TextField,
} from "@material-ui/core";

const RadioGroupCreateTutores = ({ translator, key, tutores, setTutores }) => {
  const changeResponse = (event) => {
    const name = event.target.value;
    setTutores((prev) => {
      const list = prev;
      list.forEach((element) => {
        if (element.id === tutores.id) {
          element.name = name;
        }
      });
      return list;
    });
  };

  return (
    <FormControl fullWidth key={key}>
      <TextField
        onChange={changeResponse}
        label={translator.framework.create.tutores.nome}
      />
    </FormControl>
  );
};

export default RadioGroupCreateTutores;
