import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  body: {
    backgroundColor: "#414141",
    color: "white",
    flexGrow: 2,
    paddingTop: 50,
    paddingBottom: 50,
    marginTop: 40,
    textAlign: "center",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  title: {
    fontSize: 30,
  },
  descricao: {
    fontSize: 15,
    textAlign: "justify",
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
  },
}));

function About(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <div id="about" className={classes.body}>
      <Container fixed>
        <span className={classes.title}>
          <strong>{translator.main.about.title}</strong>
        </span>
        <p className={classes.descricao}>{translator.main.about.descricao}</p>
      </Container>
    </div>
  );
}

export default About;
