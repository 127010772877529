import React from "react";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@material-ui/core";

const RadioGroupCreateAliados = ({ translator, key, aliado, setAliados }) => {
  const changeName = (event) =>{
    const name = event.target.value;
    setAliados((prev) => {
      const list = prev;
      list.forEach((element) => {
        if (element.id === aliado.id) {
          element.name = name;
        }
      });
      return list;
    });
  }
  const changeResponse = (event) => {
    const value = event.target.value;
    setAliados((prev) => {
      const list = prev;
      list.forEach((element) => {
        if (element.id === aliado.id) {
          element.type = value;
        }
      });
      return list;
    });
  };

  return (
    <FormControl fullWidth key={key}>
      <TextField
        onChange={changeName}
        label={translator.framework.create.aliados.nome}
      />
      <RadioGroup onChange={changeResponse} aria-label="gender">
        <div>
          <FormControlLabel
            value={translator.framework.create.aliados.response1}
            control={<Radio />}
            label={translator.framework.create.aliados.response1}
          />
          <FormControlLabel
            value={translator.framework.create.aliados.response2}
            control={<Radio />}
            label={translator.framework.create.aliados.response2}
          />
          <FormControlLabel
            value={translator.framework.create.aliados.response3}
            control={<Radio />}
            label={translator.framework.create.aliados.response3}
          />
        </div>
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupCreateAliados;
