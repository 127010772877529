import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    textAlign: "center",
    backgroundColor: "#c5192d",
    height: 250,
    display: "flex",
    justifyContent: "center",
  },
  osd: {},
  imgOne: {
    width: 120,
    marginTop:'60%',
  },
  imgTwo: {
    marginTop:'16%',
    width:400
  },
  textOds: {
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
    textAlign: "justify",
    color: "#ffffff",
    fontSize: 14,
    width: "30%",
    position:'relative'
  },
  childrenText:{
    position: "absolute",
    top: "20%",
    fontFamily:
    "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace"
  }
}));

function Ods(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <Box className={classes.body}>
      <Box>
        <img
          className={classes.imgOne}
          src="images/icon-ods4.png"
          alt=""
        />
      </Box>
      <Box>
        <img
          className={classes.imgTwo}
          src="images/ods4.png"
          alt=""
        />
      </Box>
      <Box className={classes.textOds}>
        <span className={classes.childrenText}>
          <span>
            <span>
              <strong>4.3</strong>
            </span>{" "}
            {translator.main.ods4.descricao1}
          </span>
          <p />
          <span>
            <span>
              <strong> 4.5</strong>
            </span>{" "}
            {translator.main.ods4.descricao2} 
          </span>
        </span>
      </Box>
    </Box>
  );
}

export default Ods;
