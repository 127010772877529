import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import ItemCar from './ItemCar';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
    formControl: {
        margin: theme.spacing(3),
    },
    selectEmpty: {
        width: 300,
    },
    grid: {
        margin: theme.spacing(1),
    },
}));

function Cards(props) {
    const classes = useStyles();
    const { translator } = props;
    const [journeys, setJourneys] = useState([]);
    const [journey, setJourney] = useState([]);
    const [id, setId] = useState('');
    const ApiJourneys = 'http://heroicjourneys.life:5000/journeys'
    const ApiJourney = 'http://heroicjourneys.life:5000/journey'

    useEffect(() => {
        document.title = 'Heroic Journeys'
        async function fetchData() {
            await fetch(ApiJourneys, {
                method: "GET",
            })
            .then(res => res.json())
            .then(data => {
                setJourneys(data)
            }).catch((error) => {
                console.log(error)
            })
        }
        fetchData()
    }, []);

    

    const handleChange = async (event) => {
        setId(event.target.value)
        setJourney([]);
        await fetch(`${ApiJourney}/${event.target.value}`, {
            method: 'GET'
        })
        .then(res => res.json())
        .then(data => {
            const { stages } = data
            setJourney(stages)
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel >{translator.main.cards.select}</InputLabel>
                <Select
                    className={classes.selectEmpty}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={id}
                    onChange={handleChange}>
                    {journeys.map((journey, index) => (
                        <MenuItem key={index} value={journey.id}> {journey.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid container spacing={2}>
                {
                    journey.map((item, index) => (
                        <Grid item key={index} lg={3} md={4} sm={6} xs={12}>
                            <ItemCar translator={translator} key={index} index={`ck-${index}`} url={item.url} name={item.name} image={item.image} />
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
}

export default Cards