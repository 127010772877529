import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: "40px",
  },
  title: {
    fontSize: "30px",
    padding: "20px",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  description: {
    fontSize: 18,
    textAlign: "justify",
    width: "80%",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
}));

function Introduction(props) {
  const classes = useStyles();
  const { translator } = props;

  useEffect(() => {
    const element = document.getElementById("decription");
    const perfil = document.getElementsByTagName("b");
    element.innerHTML = translator.framework.description;
    for (let index = 0; index < perfil.length; index++) {
       perfil[index].style.color = "#57acb0";
    }
  }, [translator]);

  return (
    <>
      <div className={classes.body}>
        <Container fixed>
          <span id="decription" className={classes.description}>
            {translator.framework.description}
          </span>
        </Container>
      </div>
    </>
  );
}

export default Introduction;
