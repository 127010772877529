import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  body: {
    backgroundColor: "#414141",
    color: "white",
    flexGrow: 2,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "center",
    fontFamily:
    "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace"
  },
  title: {
    fontSize: 25,
  },
  descricao: {
    fontSize: 15,
  },
}));

function Contato(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <div className={classes.body}>
      <Container fixed>
        <span className={classes.title}>
          <strong>{translator.main.contato.title}</strong>
        </span>
        <p className={classes.descricao}>jhaproject2020@gmail.com</p>
      </Container>
    </div>
  );
}

export default Contato;
