import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    textAlign: "center",
    backgroundColor: "#a21942",
    height: 200,
    display: "flex",
    justifyContent: "center",
  },
  osd: {},
  imgOne: {
    width: 100,
    marginTop: "42%",
    paddingLeft: 10,
  },
  imgTwo: {
    marginTop: "7%",
    width: 420,
  },
  textOds: {
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
    textAlign: "justify",
    color: "#ffffff",
    fontSize: 14,
    width: "30%",
    position: "relative",
  },
  childrenText: {
    position: "absolute",
    top: "32%",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
}));

function Ods(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <Box className={classes.body}>
      <Box>
        <img className={classes.imgOne} src="images/icon-ods8.png" alt="" />
      </Box>
      <Box>
        <img className={classes.imgTwo} src="images/ods8.png" alt="" />
      </Box>
      <Box className={classes.textOds}>
        <span className={classes.childrenText}>
          <span>
            <span>
              <strong>8.5</strong>
            </span>{" "}
            {translator.main.ods8.descricao1}
          </span>
        </span>
      </Box>
    </Box>
  );
}

export default Ods;
