import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  FormControl,
  Grid,
  Paper,
  InputLabel,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@material-ui/core";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SaveIcon from "@material-ui/icons/Save";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import campoFormulario from "../../../utils/CampoFormulario";
import RadioGroupCreateAliados from "../radiGroupCreateAliados";
import RadioGroupCreateTutores from "../radiGroupCreateTutores";
import RadioGroupCreateMaeDeusa from "../radiGroupCreateMaeDeusa";
import RadioGroupCreateAthena from "../radiGroupCreateAthena";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: "100vh",
    paddingTop: "40px",
    paddingBottom: "40px",
  },
  use: {},
  share: {
    margin: "30px 0px",
  },
  legend: {
    color: "red",
    fontWeight: "bold",
    fontSize: 15,
    display: "flex",
    justifyContent: "flex-end",
  },
  grid: {},
  formControl: {
    minWidth: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    flex: 1,
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  paperSub: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    flex: 1,
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  page: {
    display: "inline",
    float: "right",
  },
  pageGroup: {
    display: "flex",
    flexDirection: "row",
    verticalAlign: "center",
  },
  pageBtn: {
    fontSize: 22,
    padding: 6,
    color: "#57acb0",
    "&:nth-child(1)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "green",
    },
    "&:nth-child(1):hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "darkGreen",
    },
    "&:nth-child(2):hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "gray",
    },
    "&:nth-child(3):hover": {
      fontStyle: "bold",
      cursor: "none",
    },
    "&:nth-child(4):hover": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "gray",
    },
  },
  grounpBtnDesafio: {
    display: "flex",
    flexDirection: "row",
    verticalAlign: "center",
    justifyContent: "flex-end",
  },
  btn: {
    fontSize: "30px",
    padding: 5,
    "&:nth-child(1)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "DarkRed",
    },
    "&:nth-child(1):hover": {
      fontStyle: "bold",
      color: "gray",
    },
    "&:nth-child(2)": {
      fontStyle: "bold",
      cursor: "pointer",
      color: "DarkGreen",
    },
    "&:nth-child(2):hover": {
      fontStyle: "bold",
      color: "gray",
    },
  },
  description: {
    marginTop: "40px",
    fontSize: 20,
    textAlign: "justify",
    width: "80%",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  alert: {
    position: "fixed",
    width: 300,
    bottom: 120,
    right: 20,
    zIndex: 2,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

function Create(props) {
  const classes = useStyles();
  const { translator, user } = props;
  const [response, setResponse] = useState({});
  const [page, setPage] = useState(1);
  const [rend, setRend] = useState();

  const [viewLoading, setViewLoading] = useState(false);
  const [viewAlert, setViewAlert] = useState(0);

  const [tutores, setTutores] = useState([]);
  const [qtTutores, setQtTutores] = useState(0);

  const [aliados, setAliados] = useState([]);
  const [qtAliados, setQtAliados] = useState(0);

  const [desafio1link, setDesafio1link] = useState("");

  const [maeDeusa, setMaeDeusa] = useState([{ etapa1: "", etapa2: "" }]);
  const [athena, setAthena] = useState([{ etapa1: "", etapa2: "" }]);

  const [desafio1, setDesafio1] = useState({
    font: 0,
    response: "",
    link: desafio1link,
  });

  const [videosDesafio2Topico1, setVideosDesafio2Topico1] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio4Podcast, setVideosDesafio4Podcast] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio4Manuais, setVideosDesafio4Manuais] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio4Tutoriais, setVideosDesafio4Tutoriais] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio4Videos, setVideosDesafio4Videos] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio5Topico2, setVideosDesafio5Topico2] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [ambientesDesafio5, setAmbientesDesafio5] = useState([
    {
      nome: "",
    },
  ]);

  const [videosDesafio5Topico3, setVideosDesafio5Topico3] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio5Topico5, setVideosDesafio5Topico5] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio7Topico1, setVideosDesafio7Topico1] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio7Topico2, setVideosDesafio7Topico2] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio8Topico1, setVideosDesafio8Topico1] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [videosDesafio8Topico2, setVideosDesafio8Topico2] = useState([
    {
      nome: "",
      url: "",
    },
  ]);

  const [desafio2Topico1, setDesafio2Topico1] = useState({
    response: false,
    videos: videosDesafio2Topico1,
  });

  const [desafio2, setDesafio2] = useState({
    response: "",
    newIntroduction: "",
    topico1: desafio2Topico1,
    topico2: { response: "", description: "" },
    topico3: "",
    topico4: {
      apostila: false,
      roteiro: false,
      dicas: false,
    },
    topico5: { response: "", description: "" },
    topico6: {
      quiz: false,
      leitura: false,
      exercicio: false,
      outros: false,
      default: false,
      description: "",
    },
  });

  const [desafio3, setDesafio3] = useState({
    response: "",
    response2: "",
    response3: "",
  });

  const [desafio4, setDesafio4] = useState({
    response1: false,
    response2: false,
    response3: false,
    response4: false,
    description: "",
    topico1: {
      podcats: "",
      podcatsVideos: videosDesafio4Podcast,
      tutoriais: "",
      tutoriaisVideos: videosDesafio4Tutoriais,
      manuais: "",
      manuaisVideos: videosDesafio4Manuais,
      isVideo: "",
      videos: videosDesafio4Videos,
    },
    topico2: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
    topico3: "",
  });

  const [desafio5, setDesafio5] = useState({
    topico1: {
      nome: "",
    },
    topico2: {
      isVideo: "",
      videos: videosDesafio5Topico2,
    },
    topico3: {
      nomeTopico: "",
      typeAmbiente: "",
      ambientes: ambientesDesafio5,
      isVideo: "",
      videos: videosDesafio5Topico3,
    },
    topico4: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
    topico5: {
      isVideo: "",
      videos: videosDesafio5Topico5,
    },
    topico6: "",
  });

  const [desafio6, setDesafio6] = useState({
    topico1: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
  });

  const [desafio7, setDesafio7] = useState({
    nomeTopico1: "",
    topico1: {
      isVideo: "",
      videos: videosDesafio7Topico1,
    },
    topico2: {
      subTopico: "",
      isVideo: "",
      videos: videosDesafio7Topico2,
    },
    topico3: {
      response: "",
      typeAtividade: "",
    },
    topico4: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
    topico5: "",
    topico6: {
      response: "",
      nomeAtividade: "",
    },
    topico7: {
      description: "",
      nota: 0,
    },
  });

  const [desafio8, setDesafio8] = useState({
    nomeTopico3: "",
    topico1: {
      isVideo: "",
      videos: videosDesafio8Topico1,
    },
    topico2: {
      nomeTopico: "",
      isVideo: "",
      videos: videosDesafio8Topico2,
    },
    topico3: {
      response: "",
      typeAtividade: "",
    },
    topico4: {
      typeHelp: "",
    },
    topico5: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
    topico6: {
      isSubTopico: "",
      subTopicos: [
        {
          description: "",
          isVideo: "",
          videos: [
            {
              nome: "",
              url: "",
            },
          ],
        },
      ],
    },
  });

  const [desafio9, setDesafio9] = useState({
    descriptionAvaliacao: "",
  });

  const [desafio10, setDesafio10] = useState({
    response: "",
    descriptionAvaliacao: "",
    topico1: {
      response: "",
      descriptionAvaliacao: "",
    },
    topico2: {
      response: "",
      descriptionAvaliacao: "",
    },
    topico3: {
      response: "",
      descriptionAvaliacao: "",
    },
  });

  const [desafio11, setDesafio11] = useState({
    response: "",
    description: "",
  });

  const [desafio12, setDesafio12] = useState({
    response: "",
    topico1: {
      response: "",
    },
  });

  useEffect(() => {
    setDesafio2Topico1((prev) => {
      prev.videos = videosDesafio2Topico1;
      return prev;
    });
    setDesafio2((prev) => {
      prev.topico1 = desafio2Topico1;
      return prev;
    });
  }, [videosDesafio2Topico1, desafio2Topico1]);

  useEffect(() => {
    setDesafio4((prev) => {
      prev.topico1.podcatsVideos = videosDesafio4Podcast;
      return prev;
    });
  }, [videosDesafio4Podcast, desafio4]);

  useEffect(() => {
    setDesafio4((prev) => {
      prev.topico1.manuaisVideos = videosDesafio4Manuais;
      return prev;
    });
  }, [videosDesafio4Manuais, desafio4]);

  useEffect(() => {
    setDesafio4((prev) => {
      prev.topico1.tutoriaisVideos = videosDesafio4Tutoriais;
      return prev;
    });
  }, [videosDesafio4Tutoriais, desafio4]);

  useEffect(() => {
    setDesafio4((prev) => {
      prev.topico1.videos = videosDesafio4Videos;
      return prev;
    });
  }, [videosDesafio4Videos, desafio4]);

  useEffect(() => {
    setDesafio5((prev) => {
      prev.topico2.videos = videosDesafio5Topico2;
      return prev;
    });
  }, [videosDesafio5Topico2, desafio5]);

  useEffect(() => {
    setDesafio5((prev) => {
      prev.topico3.ambientes = ambientesDesafio5;
      return prev;
    });
  }, [ambientesDesafio5, desafio5]);

  useEffect(() => {
    setDesafio5((prev) => {
      prev.topico3.videos = videosDesafio5Topico3;
      return prev;
    });
  }, [videosDesafio5Topico3, desafio5]);

  useEffect(() => {
    setDesafio5((prev) => {
      prev.topico5.videos = videosDesafio5Topico5;
      return prev;
    });
  }, [videosDesafio5Topico5, desafio5]);

  useEffect(() => {
    setDesafio7((prev) => {
      prev.topico1.videos = videosDesafio7Topico1;
      return prev;
    });
  }, [videosDesafio7Topico1, desafio7]);

  useEffect(() => {
    setDesafio7((prev) => {
      prev.topico2.videos = videosDesafio7Topico2;
      return prev;
    });
  }, [videosDesafio7Topico2, desafio7]);

  useEffect(() => {
    setDesafio8((prev) => {
      prev.topico1.videos = videosDesafio8Topico1;
      return prev;
    });
  }, [videosDesafio8Topico1, desafio8]);

  useEffect(() => {
    setDesafio8((prev) => {
      prev.topico2.videos = videosDesafio8Topico2;
      return prev;
    });
  }, [videosDesafio8Topico2, desafio8]);

  useEffect(() => {
    if (qtAliados <= 0) {
      setQtAliados(0);
      setAliados([]);
    }
  }, [qtAliados]);

  useEffect(() => {
    const item = desafio1;
    item.link = desafio1link;
    setDesafio1(item);
  }, [desafio1link, desafio1]);

  useEffect(() => {
    if (qtTutores <= 0) {
      setQtTutores(0);
      setTutores([]);
    }
  }, [qtTutores]);

  const changeAddVideos = (origem) => {
    if (origem === "desafio2Topico1") {
      setVideosDesafio2Topico1((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio4Topico1Podcast") {
      setVideosDesafio4Podcast((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio4Topico1Tutoriais") {
      setVideosDesafio4Tutoriais((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio4Topico1Manuais") {
      setVideosDesafio4Manuais((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio4Topico1Videos") {
      setVideosDesafio4Videos((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio5Topico2") {
      setVideosDesafio5Topico2((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio5Topico3") {
      setVideosDesafio5Topico3((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio5Topico5") {
      setVideosDesafio5Topico5((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio5Topico3Ambientes") {
      setAmbientesDesafio5((prevArray) => [
        ...prevArray,
        {
          nome: "",
        },
      ]);
    }

    if (origem === "desafio7Topico1") {
      setVideosDesafio7Topico1((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio7Topico2") {
      setVideosDesafio7Topico2((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio8Topico1") {
      setVideosDesafio8Topico1((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }

    if (origem === "desafio8Topico2") {
      setVideosDesafio8Topico2((prevArray) => [
        ...prevArray,
        {
          nome: "",
          url: "",
        },
      ]);
    }
  };

  const changeDesafio2nome = (event, key) => {
    setVideosDesafio2Topico1((prev) => {
      const list = [...prev];
      const nome = event.target.value;
      list.forEach((element, index) => {
        if (index === key) {
          element.nome = nome;
        }
      });
      return list;
    });
  };

  const changeDesafio2url = (event, key) => {
    setVideosDesafio2Topico1((prev) => {
      const list = [...prev];
      const url = event.target.value;
      list.forEach((element, index) => {
        if (index === key) {
          element.url = url;
        }
      });
      return list;
    });
  };

  const changeRemoveVideos = (origem) => {
    if (origem === "desafio2Topico1") {
      if (videosDesafio2Topico1.length > 1) {
        const input = [...videosDesafio2Topico1];
        input.pop();
        setVideosDesafio2Topico1(input);
      }
    }
    if (origem === "desafio4Topico1Podcast") {
      if (videosDesafio4Podcast.length > 1) {
        const input = [...videosDesafio4Podcast];
        input.pop();
        setVideosDesafio4Podcast(input);
      }
    }
    if (origem === "desafio4Topico1Tutoriais") {
      if (videosDesafio4Tutoriais.length > 1) {
        const input = [...videosDesafio4Tutoriais];
        input.pop();
        setVideosDesafio4Tutoriais(input);
      }
    }
    if (origem === "desafio4Topico1Manuais") {
      if (videosDesafio4Manuais.length > 1) {
        const input = [...videosDesafio4Manuais];
        input.pop();
        setVideosDesafio4Manuais(input);
      }
    }
    if (origem === "desafio4Topico1Videos") {
      if (videosDesafio4Videos.length > 1) {
        const input = [...videosDesafio4Videos];
        input.pop();
        setVideosDesafio4Videos(input);
      }
    }
    if (origem === "desafio5Topico2") {
      if (videosDesafio5Topico2.length > 1) {
        const input = [...videosDesafio5Topico2];
        input.pop();
        setVideosDesafio5Topico2(input);
      }
    }
    if (origem === "desafio5Topico3") {
      if (videosDesafio5Topico3.length > 1) {
        const input = [...videosDesafio5Topico3];
        input.pop();
        setVideosDesafio5Topico3(input);
      }
    }
    if (origem === "desafio5Topico5") {
      if (videosDesafio5Topico5.length > 1) {
        const input = [...videosDesafio5Topico5];
        input.pop();
        setVideosDesafio5Topico5(input);
      }
    }
    if (origem === "desafio5Topico3Ambientes") {
      if (ambientesDesafio5.length > 1) {
        const input = [...ambientesDesafio5];
        input.pop();
        setAmbientesDesafio5(input);
      }
    }
    if (origem === "desafio7Topico1") {
      if (videosDesafio7Topico1.length > 1) {
        const input = [...videosDesafio7Topico1];
        input.pop();
        setVideosDesafio7Topico1(input);
      }
    }
    if (origem === "desafio7Topico2") {
      if (videosDesafio7Topico2.length > 1) {
        const input = [...videosDesafio7Topico2];
        input.pop();
        setVideosDesafio7Topico2(input);
      }
    }
    if (origem === "desafio8Topico1") {
      if (videosDesafio8Topico1.length > 1) {
        const input = [...videosDesafio8Topico1];
        input.pop();
        setVideosDesafio8Topico1(input);
      }
    }
    if (origem === "desafio8Topico2") {
      if (videosDesafio8Topico2.length > 1) {
        const input = [...videosDesafio8Topico2];
        input.pop();
        setVideosDesafio8Topico2(input);
      }
    }
  };

  const changeTutores = (e) => {
    if (e.target.value < qtTutores) {
      const input = tutores;
      input.pop();
      setTutores(input);
    } else {
      setTutores((prevArray) => [...prevArray, { id: qtTutores, name: "" }]);
    }
    setQtTutores(parseInt(e.target.value));
  };

  const changeAliados = (e) => {
    if (e.target.value < qtAliados) {
      const input = aliados;
      input.pop();
      setAliados(input);
    } else {
      setAliados((prevArray) => [
        ...prevArray,
        { id: qtAliados, name: "", type: "" },
      ]);
    }
    setQtAliados(parseInt(e.target.value));
  };

  const changeResponse = (event) => {
    const name = event.target.name;
    switch (page) {
      case 1:
        if (event.target.value === translator.framework.create.yes) {
          response[name] = true;
        } else if (event.target.value === translator.framework.create.no) {
          response[name] = false;
        }

        for (let index = 0; index < campoFormulario.length; index++) {
          if (index <= 3) {
            if (!response[campoFormulario[index]]) {
              if (index + 1 < campoFormulario.length) {
                response[campoFormulario[index + 1]] = undefined;
              }
            }
          }
        }
        break;
      case 2:
        response[name] = event.target.value;

        if (name === "response15") {
          if (event.target.value === translator.framework.create.yes) {
            response[name] = "";
          } else if (
            event.target.value === translator.framework.create.tutores.no
          ) {
            response[name] = event.target.value;
          }
        }

        if (name === "response14") {
          if (event.target.value === translator.framework.create.yes) {
            response[name] = "";
          } else if (event.target.value === translator.framework.create.no) {
            response[name] = false;
          }
        }

        for (let index = 0; index < campoFormulario.length; index++) {
          if (index <= 3) {
            if (!response[campoFormulario[index]]) {
              if (index + 1 < campoFormulario.length) {
                response[campoFormulario[index + 1]] = undefined;
              }
            }
          }
        }

        break;
      case 3:
        if (name === "response19") {
          if (
            event.target.value === translator.framework.create.desafio1.default
          ) {
            setDesafio1({
              font: 1,
              response: event.target.value,
              link: desafio1link,
            });
          } else {
            setDesafio1link("");
            setDesafio1({
              font: 0,
              response: event.target.value,
              link: desafio1link,
            });
          }
          response[name] = desafio1;
        }
        break;
      default:
        break;
    }

    setResponse(response);
    setRend(Date.now().toString());
  };

  useEffect(() => {}, [rend]);

  useEffect(() => {
    setResponse({});
  }, [translator]);

  useEffect(() => {
    if (page > 3) {
      setPage(3);
    }
    if (page < 1) {
      setPage(1);
    }
  }, [page]);

  const changeStages = (type) => (event) => {
    type ? setPage(page + 1) : setPage(page - 1);
  };

  const saveStage = (event) => {
    let validar = true;
    setViewLoading(true);
    /*
    for (let index = 0; index < campoFormulario.length; index++) {
      if (!response[campoFormulario[index]]) {
        validar = false;
      }
    }*/
    if (validar) {
      response["userId"] = user._id;
      response["stage"] = page;
      response["createdAt"] = Date.now().toString();
      response["updatedAt"] = Date.now().toString();

      response["response15"] =
        response["response15"] !== translator.framework.create.tutores.no
          ? tutores
          : response["response15"];

      response["response16"] =
        response["response16"] !== translator.framework.create.aliados.no
          ? aliados
          : response["response16"];

      response["response17"] =
        response["response17"] !== translator.framework.create.maeDeusa.no
          ? maeDeusa
          : response["response17"];

      response["response18"] =
        response["response18"] !== translator.framework.create.athena.no
          ? athena
          : response["response18"];

      response[campoFormulario[18]] = desafio1;
      response[campoFormulario[19]] = desafio2;
      response[campoFormulario[20]] = desafio3;
      response[campoFormulario[21]] = desafio4;
      response[campoFormulario[22]] = desafio5;
      response[campoFormulario[23]] = desafio6;
      response[campoFormulario[24]] = desafio7;
      response[campoFormulario[25]] = desafio8;
      response[campoFormulario[26]] = desafio9;
      response[campoFormulario[27]] = desafio10;
      response[campoFormulario[28]] = desafio11;
      response[campoFormulario[29]] = desafio12;

      const saveLanguage = async () => {
        const url = `${process.env.REACT_APP_API}/courses`;
        await fetch(url, {
          method: "POST",
          headers: {'Access-Control-Allow-Origin': '*', 'content-type': 'aplication/json'},
          body: JSON.stringify(response),
        })
          .then((res) => res.json())
          .then((data) => {
            setViewLoading(false);
            if (data) {
              setViewAlert(1);
            } else {
              setViewAlert(2);
            }
          })
          .catch((error) => {
            setViewLoading(false);
            console.log(error);
          });
      };
      saveLanguage();
    } else {
      setViewLoading(false);
      console.log("Não pode ser enviado");
    }
  };

  const Lengenda = ({ text }) => {
    return <span className={classes.legend}>{text}</span>;
  };

  return (
    <>
      <div className={classes.body}>
        <div className={classes.loading}>
          {viewAlert === 1 && (
            <Alert
              severity="success"
              className={classes.alert}
              onClose={() => {
                setViewAlert(0);
              }}
            >
              <AlertTitle>{translator.message.messageSuccess}</AlertTitle>
              {translator.message.successCourse}
            </Alert>
          )}
          {viewAlert === 2 && (
            <Alert
              severity="error"
              className={classes.alert}
              onClose={() => {
                setViewAlert(0);
              }}
            >
              <AlertTitle> {translator.message.messageError}</AlertTitle>
              {translator.message.errorCourse}
            </Alert>
          )}
        </div>
        <Container fixed>
          <span id="decription" className={classes.description}>
            <div className={classes.user}>
              {translator.framework.create.saudacao}{" "}
              <b>
                {translator.perfil[user.perfilId]} {user.name}
              </b>{" "}
              {translator.framework.create.vamos}
              <div className={classes.page}>
                <div className={classes.pageGroup}>
                  {viewLoading ? (
                    <CircularProgress />
                  ) : (
                    response[campoFormulario[3]] === true && (
                      <SaveIcon
                        title={translator.framework.create.titleSave}
                        onClick={saveStage}
                        className={classes.pageBtn}
                      />
                    )
                  )}

                  {page !== 1 ? (
                    <SkipPreviousIcon
                      onClick={changeStages(false)}
                      className={classes.pageBtn}
                    />
                  ) : (
                    <span />
                  )}

                  <span className={classes.pageBtn}>{page} of 3</span>
                  {response[campoFormulario[3]] === true && page < 3 ? (
                    <SkipNextIcon
                      onClick={changeStages(true)}
                      className={classes.pageBtn}
                    />
                  ) : (
                    <span />
                  )}
                </div>
              </div>
            </div>
            <hr className={classes.share} />
            <FormControl className={classes.formControl}>
              {page === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.response1}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[0]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.yes}
                              control={<Radio />}
                              label={translator.framework.create.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.no}
                              control={<Radio />}
                              label={translator.framework.create.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                      {response[campoFormulario[0]] === false && (
                        <Lengenda text={translator.framework.create.legend1} />
                      )}
                    </Paper>
                  </Grid>
                  {response[campoFormulario[0]] === true && (
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <span>
                          <b>{translator.framework.create.response2}</b>
                        </span>
                        <p />
                        <p />
                        <FormControl fullWidth>
                          <RadioGroup
                            onChange={changeResponse}
                            aria-label="gender"
                            name={campoFormulario[1]}
                          >
                            <div>
                              <FormControlLabel
                                value={translator.framework.create.yes}
                                control={<Radio />}
                                label={translator.framework.create.yes}
                              />
                              <FormControlLabel
                                value={translator.framework.create.no}
                                control={<Radio />}
                                label={translator.framework.create.no}
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                        {response[campoFormulario[1]] === false && (
                          <Lengenda
                            text={translator.framework.create.legend1}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )}
                  {response[campoFormulario[1]] === true && (
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <span>
                          <b>{translator.framework.create.response3}</b>
                        </span>
                        <p />
                        <p />
                        <FormControl fullWidth>
                          <RadioGroup
                            onChange={changeResponse}
                            aria-label="gender"
                            name={campoFormulario[2]}
                          >
                            <div>
                              <FormControlLabel
                                value={translator.framework.create.yes}
                                control={<Radio />}
                                label={translator.framework.create.yes}
                              />
                              <FormControlLabel
                                value={translator.framework.create.no}
                                control={<Radio />}
                                label={translator.framework.create.no}
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                        {response[campoFormulario[2]] === false && (
                          <Lengenda
                            text={translator.framework.create.legend1}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )}
                  {response[campoFormulario[2]] === true && (
                    <Grid item xs={12} sm={12}>
                      <Paper className={classes.paper}>
                        <span>
                          <b>{translator.framework.create.response4}</b>
                        </span>
                        <p />
                        <p />
                        <FormControl fullWidth>
                          <RadioGroup
                            onChange={changeResponse}
                            aria-label="gender"
                            name={campoFormulario[3]}
                          >
                            <div>
                              <FormControlLabel
                                value={translator.framework.create.yes}
                                control={<Radio />}
                                label={translator.framework.create.yes}
                              />
                              <FormControlLabel
                                value={translator.framework.create.no}
                                control={<Radio />}
                                label={translator.framework.create.no}
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                        {response[campoFormulario[3]] === false && (
                          <Lengenda
                            text={translator.framework.create.legend1}
                          />
                        )}
                      </Paper>
                    </Grid>
                  )}
                </Grid>
              )}
              {page === 2 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.name}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[4]}
                          value={response[campoFormulario[4]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.anoEdicao}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[5]}
                          type="number"
                          inputProps={{
                            maxlength: 4,
                          }}
                          value={response[campoFormulario[5]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.descricao}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[6]}
                          value={response[campoFormulario[6]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.publicoAlvo}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[7]}
                          value={response[campoFormulario[7]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.objetivo}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[8]}
                          value={response[campoFormulario[8]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.preRequisito}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[9]}
                          value={response[campoFormulario[9]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.conteudo}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <TextField 
                          name={campoFormulario[10]}
                          label={translator.framework.create.campoObrigatorio}
                          value={response[campoFormulario[10]]}
                          multiline
                          rows={10}
                          onChange={changeResponse}
                          variant="outlined"
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.estrategiaPedagogica}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[11]}
                          value={response[campoFormulario[11]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.metodologiaAvalicacao}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.campoObrigatorio}
                        </InputLabel>
                        <Input
                          name={campoFormulario[12]}
                          value={response[campoFormulario[12]]}
                          onChange={changeResponse}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {user.perfilId === "1" &&
                            translator.framework.create.teacherDesigner
                              .designer}
                          {user.perfilId === "2" &&
                            translator.framework.create.teacherDesigner.teacher}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[13]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.yes}
                              control={<Radio />}
                              label={
                                translator.framework.create.teacherDesigner.yes
                              }
                            />
                            <FormControlLabel
                              value={translator.framework.create.no}
                              control={<Radio />}
                              label={
                                translator.framework.create.teacherDesigner.no
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      {response[campoFormulario[13]] === false &&
                      response[campoFormulario[13]] != null ? (
                        <Lengenda
                          text={
                            translator.framework.create.teacherDesigner.legend
                          }
                        />
                      ) : response[campoFormulario[13]] != null ? (
                        <FormControl fullWidth>
                          <InputLabel htmlFor="standard-adornment-amount">
                            {
                              translator.framework.create.teacherDesigner
                                .campoObrigatorio
                            }
                          </InputLabel>
                          <Input
                            name={campoFormulario[13]}
                            value={response[campoFormulario[13]]}
                            onChange={changeResponse}
                          />
                        </FormControl>
                      ) : (
                        ""
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.tutores.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[14]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.tutores.yes}
                              control={<Radio />}
                              label={translator.framework.create.tutores.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.tutores.no}
                              control={<Radio />}
                              label={translator.framework.create.tutores.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      {response[campoFormulario[14]] ===
                        translator.framework.create.tutores.yes && (
                        <>
                          <TextField
                            id="standard-number"
                            label={
                              translator.framework.create.tutores.qtAliados
                            }
                            type="number"
                            onChange={(e) => {
                              changeTutores(e);
                            }}
                            value={qtTutores}
                          />
                          <p />
                          {tutores.map((tutor, key) => (
                            <RadioGroupCreateTutores
                              translator={translator}
                              tutores={tutor}
                              setTutores={setTutores}
                              key={key}
                            />
                          ))}
                        </>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.aliados.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[15]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.aliados.yes}
                              control={<Radio />}
                              label={translator.framework.create.aliados.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.aliados.no}
                              control={<Radio />}
                              label={translator.framework.create.aliados.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      {response[campoFormulario[15]] ===
                        translator.framework.create.aliados.yes && (
                        <>
                          <TextField
                            id="standard-number"
                            label={
                              translator.framework.create.aliados.qtAliados
                            }
                            type="number"
                            onChange={(e) => {
                              changeAliados(e);
                            }}
                            value={qtAliados}
                          />
                          <p />
                          {aliados.map((aliado, key) => (
                            <RadioGroupCreateAliados
                              translator={translator}
                              aliado={aliado}
                              setAliados={setAliados}
                              key={key}
                            />
                          ))}
                        </>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.maeDeusa.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[16]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.maeDeusa.yes}
                              control={<Radio />}
                              label={translator.framework.create.maeDeusa.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.maeDeusa.no}
                              control={<Radio />}
                              label={translator.framework.create.maeDeusa.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      {response[campoFormulario[16]] ===
                        translator.framework.create.maeDeusa.yes && (
                        <RadioGroupCreateMaeDeusa
                          translator={translator}
                          setMaeDeusa={setMaeDeusa}
                        />
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.athena.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[17]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.athena.yes}
                              control={<Radio />}
                              label={translator.framework.create.athena.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.athena.no}
                              control={<Radio />}
                              label={translator.framework.create.athena.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>

                      {response[campoFormulario[17]] ===
                        translator.framework.create.athena.yes && (
                        <RadioGroupCreateAthena
                          translator={translator}
                          setAthena={setAthena}
                        />
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              )}
              {page === 3 && (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio1.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio1.question}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={changeResponse}
                          aria-label="gender"
                          name={campoFormulario[18]}
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.desafio1.tutor}
                              control={<Radio />}
                              label={translator.framework.create.desafio1.tutor}
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio1.teacher
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio1.teacher
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio1.maeDeusa
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio1.maeDeusa
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio1.aliados
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio1.aliados
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio1.default
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio1.default
                              }
                            />
                            {desafio1.font === 1 && (
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio1
                                      .titleLink
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio1link}
                                  onChange={(e) => {
                                    setDesafio1link(e.target.value);
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio2.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio2.question}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio2((prev) => {
                              const desafio = { ...prev };
                              desafio.response = e.target.value;
                              desafio.newIntroduction = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.default
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.default
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.default2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.default2
                              }
                            />
                            {desafio2.response ===
                              translator.framework.create.desafio2.default2 && (
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio2
                                      .titleIntroduction
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio2.newIntroduction}
                                  onChange={(e) => {
                                    setDesafio2((prev) => {
                                      const desafio = { ...prev };
                                      desafio.newIntroduction = e.target.value;
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico1.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            if (
                              e.target.value ===
                              translator.framework.create.desafio2.topico1.yes
                            ) {
                              setDesafio2Topico1({ response: true });
                            } else {
                              setDesafio2Topico1({ response: false });
                            }
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico1.no
                              }
                            />
                            {desafio2Topico1.response && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio2
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={() => {
                                          changeRemoveVideos("desafio2Topico1");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={() => {
                                          changeAddVideos("desafio2Topico1");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <p />
                                  <p />
                                  {videosDesafio2Topico1.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio2.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              changeDesafio2nome(e, key);
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio2.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              changeDesafio2url(e, key);
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico2.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(event) => {
                            const response = event.target.value;

                            if (
                              response !==
                              translator.framework.create.desafio2.topico2
                            ) {
                              setDesafio2((prev) => {
                                const list = { ...prev };
                                list.topico2.response = response;
                                list.topico2.description = "";
                                return list;
                              });
                            } else {
                              setDesafio2({ response: response });
                            }
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico2
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico2
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico2
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico2
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico2
                                  .default
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico2
                                  .default
                              }
                            />
                            {desafio2.topico2.response ===
                              translator.framework.create.desafio2.topico2
                                .default && (
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio2.topico2
                                      .titleCampo
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio2.topico2.description}
                                  onChange={(event) => {
                                    setDesafio2((prev) => {
                                      const list = { ...prev };
                                      const description = event.target.value;
                                      list.topico2.description = description;
                                      return list;
                                    });
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico3.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio2((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3 = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico3
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico3
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico3
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico3
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico3
                                  .response3
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico3
                                  .response3
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico4.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico4.apostila}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico4.apostila = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico4
                                .response1
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico4.roteiro}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico4.roteiro = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico4
                                .response2
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico4.dicas}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico4.dicas = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico4
                                .response3
                            }
                          />
                        </div>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico5.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio2((prev) => {
                              const desafio = { ...prev };
                              desafio.topico5.response = e.target.value;
                              desafio.topico5.description = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response3
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response3
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response6
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response6
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response4
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response4
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .response5
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .response5
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio2.topico5
                                  .default
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio2.topico5
                                  .default
                              }
                            />
                            {desafio2.topico5.response ===
                              translator.framework.create.desafio2.topico5
                                .response5 && (
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio2.topico5
                                      .titleCampo
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio2.topico5.description}
                                  onChange={(e) => {
                                    setDesafio2((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico5.description =
                                        e.target.value;
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio2.topico6.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico6.quiz}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.quiz = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico6
                                .response1
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico6.leitura}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.leitura = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico6
                                .response2
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico6.exercicio}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.exercicio =
                                      e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico6
                                .response3
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico6.outros}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.outros = e.target.checked;
                                    desafio.topico6.description = "";
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico6
                                .response4
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio2.topico6.default}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.default = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio2.topico6
                                .default
                            }
                          />

                          {desafio2.topico6.outros && (
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio2.topico6
                                    .titleCampo
                                }
                              </InputLabel>
                              <Input
                                value={desafio2.topico6.description}
                                onChange={(e) => {
                                  setDesafio2((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico6.description =
                                      e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          )}
                        </div>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio3.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio3.subTitle}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio3((prev) => {
                              const desafio = { ...prev };
                              desafio.response = e.target.value;
                              desafio.response2 = "";
                              desafio.response3 = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.desafio3.yes}
                              control={<Radio />}
                              label={translator.framework.create.desafio3.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.desafio3.no}
                              control={<Radio />}
                              label={translator.framework.create.desafio3.no}
                            />

                            {desafio3.response ===
                              translator.framework.create.desafio3.yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <span>
                                    <b>
                                      {
                                        <b>
                                          {
                                            translator.framework.create.desafio3
                                              .title2
                                          }
                                        </b>
                                      }
                                    </b>
                                  </span>
                                  <p />
                                  <p />
                                  <FormControl fullWidth>
                                    <RadioGroup
                                      onChange={(e) => {
                                        setDesafio3((prev) => {
                                          const desafio = { ...prev };
                                          desafio.response2 = e.target.value;
                                          desafio.response3 = "";
                                          return desafio;
                                        });
                                      }}
                                      aria-label="gender"
                                    >
                                      <div>
                                        <FormControlLabel
                                          value={
                                            translator.framework.create.desafio3
                                              .response1
                                          }
                                          control={<Radio />}
                                          label={
                                            translator.framework.create.desafio3
                                              .response1
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            translator.framework.create.desafio3
                                              .response2
                                          }
                                          control={<Radio />}
                                          label={
                                            translator.framework.create.desafio3
                                              .response2
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            translator.framework.create.desafio3
                                              .response3
                                          }
                                          control={<Radio />}
                                          label={
                                            translator.framework.create.desafio3
                                              .response3
                                          }
                                        />
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                </Paper>
                              </Grid>
                            )}

                            {desafio3.response ===
                              translator.framework.create.desafio3.no && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <span>
                                    <b>
                                      {
                                        <b>
                                          {
                                            translator.framework.create.desafio3
                                              .title3
                                          }
                                        </b>
                                      }
                                    </b>
                                  </span>
                                  <p />
                                  <p />
                                  <FormControl fullWidth>
                                    <RadioGroup
                                      onChange={(e) => {
                                        setDesafio3((prev) => {
                                          const desafio = { ...prev };
                                          desafio.response3 = e.target.value;
                                          desafio.response2 = "";
                                          return desafio;
                                        });
                                      }}
                                      aria-label="gender"
                                    >
                                      <div>
                                        <FormControlLabel
                                          value={
                                            translator.framework.create.desafio3
                                              .response4
                                          }
                                          control={<Radio />}
                                          label={
                                            translator.framework.create.desafio3
                                              .response4
                                          }
                                        />
                                        <FormControlLabel
                                          value={
                                            translator.framework.create.desafio3
                                              .response5
                                          }
                                          control={<Radio />}
                                          label={
                                            translator.framework.create.desafio3
                                              .response5
                                          }
                                        />
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio4.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio4.subTitle}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio4.response1}
                                onChange={(e) => {
                                  setDesafio4((prev) => {
                                    const desafio = { ...prev };
                                    desafio.response1 = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio4.response1
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio4.response2}
                                onChange={(e) => {
                                  setDesafio4((prev) => {
                                    const desafio = { ...prev };
                                    desafio.response2 = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio4.response2
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio4.response3}
                                onChange={(e) => {
                                  setDesafio4((prev) => {
                                    const desafio = { ...prev };
                                    desafio.response3 = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio4.response3
                            }
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={desafio4.response4}
                                onChange={(e) => {
                                  setDesafio4((prev) => {
                                    const desafio = { ...prev };
                                    desafio.response4 = e.target.checked;
                                    return desafio;
                                  });
                                }}
                              />
                            }
                            label={
                              translator.framework.create.desafio4.response4
                            }
                          />
                          {desafio4.response3 && (
                            <Grid item xs={12} sm={12}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio4
                                      .titleCampo
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio4.description}
                                  onChange={(e) => {
                                    setDesafio4((prev) => {
                                      const desafio = { ...prev };
                                      desafio.description = e.target.value;
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </div>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico1.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico1.title1}
                        </b>
                      </span>
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio4((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.podcats = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio4Podcast([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.no
                              }
                            />
                            {desafio4.topico1.podcats ===
                              translator.framework.create.desafio4.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio4
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos(
                                            "desafio4Topico1Podcast"
                                          );
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos(
                                            "desafio4Topico1Podcast"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio4Podcast.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio4Podcast(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio4Podcast(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <p />
                      <p />
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico1.title2}
                        </b>
                      </span>
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio4((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.tutoriais = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio4Tutoriais([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.no
                              }
                            />
                            {desafio4.topico1.tutoriais ===
                              translator.framework.create.desafio4.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio4
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos(
                                            "desafio4Topico1Tutoriais"
                                          );
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos(
                                            "desafio4Topico1Tutoriais"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio4Tutoriais.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio4Tutoriais(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio4Tutoriais(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <p />
                      <p />
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico1.title3}
                        </b>
                      </span>
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio4((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.manuais = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio4Manuais([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.no
                              }
                            />
                            {desafio4.topico1.manuais ===
                              translator.framework.create.desafio4.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio4
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos(
                                            "desafio4Topico1Manuais"
                                          );
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos(
                                            "desafio4Topico1Manuais"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio4Manuais.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio4Manuais(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio4Manuais(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <p />
                      <p />
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico1.title4}
                        </b>
                      </span>
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio4((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio4Videos([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico1.no
                              }
                            />
                            {desafio4.topico1.isVideo ===
                              translator.framework.create.desafio4.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio4
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos(
                                            "desafio4Topico1Videos"
                                          );
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos(
                                            "desafio4Topico1Videos"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio4Videos.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio4Videos(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio4.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio4Videos(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio4.topico2
                                  .title
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio4.topico2.isSubTopico ===
                          translator.framework.create.desafio4.topico2.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio4((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico2.subTopicos.length > 1) {
                                    desafio.topico2.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio4((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico2.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio4((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico2.isSubTopico = e.target.value;
                                  desafio.topico2.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio4.topico2
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio4.topico2
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio4.topico2
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio4.topico2
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio4.topico2.isSubTopico ===
                        translator.framework.create.desafio4.topico2.yes &&
                        desafio4.topico2.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio4.topico2
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio4((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico2.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio4.topico2
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio4((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico2.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio4
                                          .topico2.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio4
                                          .topico2.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio4
                                          .topico2.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio4
                                          .topico2.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio4
                                        .topico2.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio4.topico2
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio4((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico2.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio4((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico2.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio4
                                                          .topico2.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio4((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico2.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio4
                                                          .topico2.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio4((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico2.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio4.topico3.title}
                        </b>
                      </span>
                      <p />
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio4.topico3
                              .subTitle
                          }
                        </b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio4((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3 = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico3
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico3
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio4.topico3
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio4.topico3
                                  .response2
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio5.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>
                          {translator.framework.create.desafio5.topico1.title}
                        </b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {
                            translator.framework.create.desafio5.topico1
                              .placeholder
                          }
                        </InputLabel>
                        <Input
                          value={desafio5.topico2.nome}
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.nome = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio5.topico2.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio5Topico2([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico2.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico2.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico2.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico2.no
                              }
                            />
                            {desafio5.topico2.isVideo ===
                              translator.framework.create.desafio5.topico2
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio5
                                              .topico2.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio5Topico2");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio5Topico2");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio5Topico2.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico2.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico2.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio5.topico3.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {
                            translator.framework.create.desafio5.topico3
                              .placeholder
                          }
                        </InputLabel>
                        <Input
                          value={desafio5.topico3.nomeTopico}
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.nomeTopico = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio5.topico3
                              .question
                          }
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.ambiente = e.target.value;
                              return desafio;
                            });
                            setAmbientesDesafio5([{ nome: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico3
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico3
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico3
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico3
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico3
                                  .response3
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico3
                                  .response3
                              }
                            />
                            {desafio5.topico3.ambiente ===
                              translator.framework.create.desafio5.topico3
                                .response3 && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio5
                                              .topico2.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos(
                                            "desafio5Topico3Ambientes"
                                          );
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos(
                                            "desafio5Topico3Ambientes"
                                          );
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {ambientesDesafio5.map((ambiente, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={12} sm={12}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico2.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={ambiente.nome}
                                            onChange={(e) => {
                                              setAmbientesDesafio5((prev) => {
                                                const desafio = [...prev];
                                                desafio.forEach(
                                                  (ambiente, index) => {
                                                    if (index === key) {
                                                      ambiente.nome =
                                                        e.target.value;
                                                    }
                                                  }
                                                );
                                                return desafio;
                                              });
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio5.topico3
                              .question2
                          }
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio5Topico3([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico3.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico3.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico3.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico3.no
                              }
                            />
                            {desafio5.topico3.isVideo ===
                              translator.framework.create.desafio5.topico3
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio5
                                              .topico2.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio5Topico3");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio5Topico3");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio5Topico3.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico3.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico3(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico3.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico3(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio5.topico4
                                  .title
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio5.topico4.isSubTopico ===
                          translator.framework.create.desafio5.topico4.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio5((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico4.subTopicos.length > 1) {
                                    desafio.topico4.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio5((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico4.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio5((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico4.isSubTopico = e.target.value;
                                  desafio.topico4.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio5.topico4
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio5.topico4
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio5.topico4
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio5.topico4
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio5.topico4.isSubTopico ===
                        translator.framework.create.desafio5.topico4.yes &&
                        desafio5.topico4.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio5.topico4
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio5((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico4.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio5.topico4
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio5((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico4.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio5
                                          .topico4.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio5
                                          .topico4.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio5
                                          .topico4.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio5
                                          .topico4.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio5
                                        .topico4.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio5.topico4
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio5((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico4.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio5((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico4.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio5
                                                          .topico4.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio5((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico4.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio5
                                                          .topico4.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio5((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico4.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio5.topico5.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico5.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio5Topico5([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico5.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico5.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico5.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico5.no
                              }
                            />
                            {desafio5.topico5.isVideo ===
                              translator.framework.create.desafio5.topico5
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio5
                                              .topico5.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio5Topico5");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio5Topico5");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio5Topico5.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico5.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico5(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio5.topico5.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio5Topico5(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio5.topico6.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio5((prev) => {
                              const desafio = { ...prev };
                              desafio.topico6 = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico6
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico6
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico6
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico6
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio5.topico5.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio5.topico5.no
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio6.topico1
                                  .title
                              }
                            </b>
                          </span>
                          <p />
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio6.topico1
                                  .subTitle
                              }
                            </b>
                          </span>
                          <p />
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio6.topico1
                                  .subTitle2
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio6.topico1.isSubTopico ===
                          translator.framework.create.desafio6.topico1.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio6((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico1.subTopicos.length > 1) {
                                    desafio.topico1.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio6((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico1.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio6((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico1.isSubTopico = e.target.value;
                                  desafio.topico1.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio6.topico1
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio6.topico1
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio6.topico1
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio6.topico1
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio6.topico1.isSubTopico ===
                        translator.framework.create.desafio6.topico1.yes &&
                        desafio6.topico1.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio6.topico1
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio6((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico1.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>

                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio6.topico1
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio6((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico1.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio6
                                          .topico1.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio6
                                          .topico1.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio6
                                          .topico1.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio6
                                          .topico1.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio6
                                        .topico1.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio6.topico1
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio6((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico1.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio6((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico1.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio6
                                                          .topico1.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio6((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico1.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio6
                                                          .topico1.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio6((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico1.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio7.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.desafio7.placeholder}
                        </InputLabel>
                        <Input
                          value={desafio7.nomeTopico1}
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.nomeTopico1 = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico1.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio7Topico1([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico1.no
                              }
                            />
                            {desafio7.topico1.isVideo ===
                              translator.framework.create.desafio7.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio7
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio7Topico1");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio7Topico1");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio7Topico1.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio7.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio7Topico1(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio7.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio7Topico1(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico2.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {
                            translator.framework.create.desafio7.topico2
                              .placeholder
                          }
                        </InputLabel>
                        <Input
                          value={desafio7.topico2.subTopico}
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.subTopico = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio7.topico2
                              .question
                          }
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio7Topico2([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico2.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico2.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico2.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico2.no
                              }
                            />
                            {desafio7.topico2.isVideo ===
                              translator.framework.create.desafio7.topico2
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio7
                                              .topico2.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio7Topico2");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio7Topico2");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio7Topico2.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio7.topico2.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio7Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio7.topico2.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio7Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico3.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.response = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico3.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico3.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico3.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico3.no
                              }
                            />
                            {desafio7.topico3.response ===
                              translator.framework.create.desafio7.topico3
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="standard-adornment-amount">
                                      {
                                        translator.framework.create.desafio7
                                          .topico3.placeholder
                                      }
                                    </InputLabel>
                                    <Input
                                      value={desafio7.topico3.typeAtividade}
                                      onChange={(e) => {
                                        setDesafio7((prev) => {
                                          const desafio = { ...prev };
                                          desafio.topico3.typeAtividade =
                                            e.target.value;
                                          return desafio;
                                        });
                                      }}
                                    />
                                  </FormControl>
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio7.topico4
                                  .title
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio7.topico4.isSubTopico ===
                          translator.framework.create.desafio7.topico4.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio7((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico4.subTopicos.length > 1) {
                                    desafio.topico4.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio7((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico4.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio7((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico4.isSubTopico = e.target.value;
                                  desafio.topico4.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio7.topico4
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio7.topico4
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio7.topico4
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio7.topico4
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio7.topico4.isSubTopico ===
                        translator.framework.create.desafio7.topico4.yes &&
                        desafio7.topico4.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio7.topico4
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio7((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico4.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>

                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio7.topico4
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio7((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico4.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio7
                                          .topico4.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio7
                                          .topico4.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio7
                                          .topico4.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio7
                                          .topico4.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio4
                                        .topico1.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio7.topico4
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio7((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico4.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio7((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico4.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio7
                                                          .topico4.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio7((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico4.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio7
                                                          .topico4.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio7((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico4.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico5.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico5 = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico5
                                  .response1
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico5
                                  .response1
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico5
                                  .response2
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico5
                                  .response2
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico5
                                  .response3
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico5
                                  .response3
                              }
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico6.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio7((prev) => {
                              const desafio = { ...prev };
                              desafio.topico6.response = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico6.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico6.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio7.topico6.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio7.topico6.no
                              }
                            />
                            {desafio7.topico6.response ===
                              translator.framework.create.desafio7.topico6
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="standard-adornment-amount">
                                      {
                                        translator.framework.create.desafio7
                                          .topico6.placeholder
                                      }
                                    </InputLabel>
                                    <Input
                                      value={desafio7.topico6.nomeAtividade}
                                      onChange={(e) => {
                                        setDesafio7((prev) => {
                                          const desafio = { ...prev };
                                          desafio.topico6.nomeAtividade =
                                            e.target.value;
                                          return desafio;
                                        });
                                      }}
                                    />
                                  </FormControl>
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio7.topico7.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <Grid container spacing={2}>
                          <Grid item xs={9} sm={9}>
                            <Paper className={classes.paper}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio7.topico7
                                      .placeholderAtividade
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio7.topico7.description}
                                  onChange={(e) => {
                                    setDesafio7((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico7.description =
                                        e.target.value;
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          <Grid item xs={3} sm={3}>
                            <Paper className={classes.paper}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio7.topico7
                                      .placeholderNota
                                  }
                                </InputLabel>
                                <Input
                                  type="number"
                                  value={desafio7.topico7.nota}
                                  onChange={(e) => {
                                    setDesafio7((prev) => {
                                      const desafio = { ...prev };
                                      if (parseInt(e.target.value) >= 0) {
                                        desafio.topico7.nota = parseFloat(
                                          e.target.value
                                        );
                                      }
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                        </Grid>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio8.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.desafio8.placeholder}
                        </InputLabel>
                        <Input
                          value={desafio8.nomeTopico3}
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.nomeTopico3 = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio8.topico1.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio8Topico1([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico1.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico1.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico1.no
                              }
                            />
                            {desafio8.topico1.isVideo ===
                              translator.framework.create.desafio8.topico1
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio8
                                              .topico1.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio8Topico1");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio8Topico1");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio8Topico1.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio8.topico1.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio8Topico1(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio8.topico1.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio8Topico1(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio8.topico2.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {
                            translator.framework.create.desafio8.topico2
                              .placeholder
                          }
                        </InputLabel>
                        <Input
                          value={desafio8.topico2.nomeTopico}
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.nomeTopico = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio8.topico2
                              .subTitle
                          }
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.isVideo = e.target.value;
                              return desafio;
                            });
                            setVideosDesafio8Topico2([{ nome: "", url: "" }]);
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico2.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico2.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico2.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico2.no
                              }
                            />
                            {desafio8.topico2.isVideo ===
                              translator.framework.create.desafio8.topico2
                                .yes && (
                              <Grid item xs={12} sm={12}>
                                <Paper className={classes.paper}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={8} sm={8}>
                                      <span>
                                        <b>
                                          {
                                            translator.framework.create.desafio8
                                              .topico2.titleVideo
                                          }
                                        </b>
                                      </span>
                                    </Grid>
                                    <Grid
                                      className={classes.grounpBtnDesafio}
                                      item
                                      xs={4}
                                      sm={4}
                                    >
                                      <HighlightOffIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeRemoveVideos("desafio8Topico2");
                                        }}
                                      />
                                      <AddCircleOutlineIcon
                                        className={classes.btn}
                                        onClick={(e) => {
                                          changeAddVideos("desafio8Topico2");
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  {videosDesafio8Topico2.map((video, key) => (
                                    <Grid key={key} container spacing={2}>
                                      <Grid item xs={4} sm={4}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio8.topico2.nameVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.nome}
                                            onChange={(e) => {
                                              setVideosDesafio8Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.nome =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={8} sm={8}>
                                        <FormControl fullWidth>
                                          <InputLabel htmlFor="standard-adornment-amount">
                                            {
                                              translator.framework.create
                                                .desafio8.topico2.urlVideo
                                            }
                                          </InputLabel>
                                          <Input
                                            value={video.url}
                                            onChange={(e) => {
                                              setVideosDesafio8Topico2(
                                                (prev) => {
                                                  const desafio = [...prev];
                                                  desafio.forEach(
                                                    (video, index) => {
                                                      if (index === key) {
                                                        video.url =
                                                          e.target.value;
                                                      }
                                                    }
                                                  );
                                                  return desafio;
                                                }
                                              );
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Paper>
                              </Grid>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio8.topico3.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.response = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico2.yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico2.yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio8.topico2.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio8.topico2.no
                              }
                            />
                            {desafio8.topico3.response ===
                              translator.framework.create.desafio8.topico3
                                .yes && (
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio8.topico3
                                      .placeholder
                                  }
                                </InputLabel>
                                <Input
                                  value={desafio8.topico3.typeAtividade}
                                  onChange={(e) => {
                                    setDesafio8((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico3.typeAtividade =
                                        e.target.value;
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>
                            )}
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio8.topico4.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {
                            translator.framework.create.desafio8.topico4
                              .placeholder
                          }
                        </InputLabel>
                        <Input
                          value={desafio8.topico4.typeHelp}
                          onChange={(e) => {
                            setDesafio8((prev) => {
                              const desafio = { ...prev };
                              desafio.topico4.typeHelp = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio8.topico5
                                  .title
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio8.topico5.isSubTopico ===
                          translator.framework.create.desafio8.topico5.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico5.subTopicos.length > 1) {
                                    desafio.topico5.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico5.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico5.isSubTopico = e.target.value;
                                  desafio.topico5.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio8.topico5
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio8.topico5
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio8.topico5
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio8.topico5
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio8.topico5.isSubTopico ===
                        translator.framework.create.desafio8.topico5.yes &&
                        desafio8.topico5.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio8.topico5
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio8((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico5.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>

                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio8.topico5
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio8((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico5.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio8
                                          .topico5.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio8
                                          .topico5.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio8
                                          .topico5.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio8
                                          .topico5.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio8
                                        .topico5.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio8.topico5
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio8((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico5.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio8((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico5.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio8
                                                          .topico5.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio8((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico5.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio8
                                                          .topico5.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio8((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico5.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <Grid container spacing={2}>
                        <Grid item xs={8} sm={8}>
                          <span>
                            <b>
                              {
                                translator.framework.create.desafio8.topico6
                                  .title
                              }
                            </b>
                          </span>
                        </Grid>
                        {desafio8.topico5.isSubTopico ===
                          translator.framework.create.desafio8.topico6.yes && (
                          <Grid
                            className={classes.grounpBtnDesafio}
                            item
                            xs={4}
                            sm={4}
                          >
                            <HighlightOffIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  if (desafio.topico6.subTopicos.length > 1) {
                                    desafio.topico6.subTopicos.pop();
                                  }
                                  return desafio;
                                });
                              }}
                            />
                            <AddCircleOutlineIcon
                              className={classes.btn}
                              onClick={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico6.subTopicos.push({
                                    description: "",
                                    isVideo: "",
                                    videos: [
                                      {
                                        nome: "",
                                        url: "",
                                      },
                                    ],
                                  });
                                  return desafio;
                                });
                              }}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={12}>
                          <FormControl fullWidth>
                            <RadioGroup
                              onChange={(e) => {
                                setDesafio8((prev) => {
                                  const desafio = { ...prev };
                                  desafio.topico6.isSubTopico = e.target.value;
                                  desafio.topico6.subTopicos = [
                                    {
                                      description: "",
                                      isVideo: "",
                                      videos: [
                                        {
                                          nome: "",
                                          url: "",
                                        },
                                      ],
                                    },
                                  ];
                                  return desafio;
                                });
                              }}
                              aria-label="gender"
                            >
                              <div>
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio8.topico6
                                      .yes
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio8.topico6
                                      .yes
                                  }
                                />
                                <FormControlLabel
                                  value={
                                    translator.framework.create.desafio8.topico6
                                      .no
                                  }
                                  control={<Radio />}
                                  label={
                                    translator.framework.create.desafio8.topico6
                                      .no
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {desafio8.topico6.isSubTopico ===
                        translator.framework.create.desafio8.topico6.yes &&
                        desafio8.topico6.subTopicos.map((item, key) => (
                          <Grid container spacing={2}>
                            <Paper className={classes.paperSub}>
                              <FormControl fullWidth>
                                <InputLabel htmlFor="standard-adornment-amount">
                                  {
                                    translator.framework.create.desafio8.topico6
                                      .titleSubTopico
                                  }
                                </InputLabel>
                                <Input
                                  value={item.description}
                                  onChange={(e) => {
                                    setDesafio8((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico6.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.description = e.target.value;
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                />
                              </FormControl>

                              <p />
                              <span>
                                <b>
                                  {
                                    translator.framework.create.desafio8.topico6
                                      .title2
                                  }
                                </b>
                              </span>
                              <p />
                              <FormControl fullWidth>
                                <RadioGroup
                                  onChange={(e) => {
                                    setDesafio8((prev) => {
                                      const desafio = { ...prev };
                                      desafio.topico6.subTopicos.forEach(
                                        (topico, index) => {
                                          if (index === key) {
                                            topico.isVideo = e.target.value;
                                            topico.videos = [
                                              { nome: "", url: "" },
                                            ];
                                          }
                                        }
                                      );
                                      return desafio;
                                    });
                                  }}
                                  aria-label="gender"
                                >
                                  <div>
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio8
                                          .topico5.yes
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio8
                                          .topico5.yes
                                      }
                                    />
                                    <FormControlLabel
                                      value={
                                        translator.framework.create.desafio8
                                          .topico5.no
                                      }
                                      control={<Radio />}
                                      label={
                                        translator.framework.create.desafio8
                                          .topico5.no
                                      }
                                    />
                                    {item.isVideo ===
                                      translator.framework.create.desafio8
                                        .topico6.yes && (
                                      <Grid item xs={12} sm={12}>
                                        <Paper className={classes.paper}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={8} sm={8}>
                                              <span>
                                                <b>
                                                  {
                                                    translator.framework.create
                                                      .desafio8.topico6
                                                      .titleVideo
                                                  }
                                                </b>
                                              </span>
                                            </Grid>
                                            <Grid
                                              className={
                                                classes.grounpBtnDesafio
                                              }
                                              item
                                              xs={4}
                                              sm={4}
                                            >
                                              <HighlightOffIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio8((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico6.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          if (
                                                            topico.videos
                                                              .length > 1
                                                          ) {
                                                            topico.videos.pop();
                                                          }
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                              <AddCircleOutlineIcon
                                                className={classes.btn}
                                                onClick={(e) => {
                                                  setDesafio8((prev) => {
                                                    const desafio = { ...prev };
                                                    desafio.topico6.subTopicos.forEach(
                                                      (topico, index) => {
                                                        if (index === key) {
                                                          topico.videos.push({
                                                            nome: "",
                                                            url: "",
                                                          });
                                                        }
                                                      }
                                                    );
                                                    return desafio;
                                                  });
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          {item.videos.map(
                                            (video, keyVideo) => (
                                              <Grid
                                                key={key}
                                                container
                                                spacing={2}
                                              >
                                                <Grid item xs={4} sm={4}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio8
                                                          .topico5.nameVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.nome}
                                                      onChange={(e) => {
                                                        setDesafio8((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico6.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.nome =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                                <Grid item xs={8} sm={8}>
                                                  <FormControl fullWidth>
                                                    <InputLabel htmlFor="standard-adornment-amount">
                                                      {
                                                        translator.framework
                                                          .create.desafio8
                                                          .topico6.urlVideo
                                                      }
                                                    </InputLabel>
                                                    <Input
                                                      value={video.url}
                                                      onChange={(e) => {
                                                        setDesafio8((prev) => {
                                                          const desafio = {
                                                            ...prev,
                                                          };
                                                          desafio.topico6.subTopicos.forEach(
                                                            (topico, index) => {
                                                              if (
                                                                index === key
                                                              ) {
                                                                topico.videos.forEach(
                                                                  (
                                                                    video,
                                                                    i
                                                                  ) => {
                                                                    if (
                                                                      i ===
                                                                      keyVideo
                                                                    ) {
                                                                      video.url =
                                                                        e.target.value;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            }
                                                          );
                                                          return desafio;
                                                        });
                                                      }}
                                                    />
                                                  </FormControl>
                                                </Grid>
                                              </Grid>
                                            )
                                          )}
                                        </Paper>
                                      </Grid>
                                    )}
                                  </div>
                                </RadioGroup>
                              </FormControl>
                            </Paper>
                          </Grid>
                        ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio9.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio9.subTitle}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-amount">
                          {translator.framework.create.desafio9.placeholder}
                        </InputLabel>
                        <Input
                          value={desafio9.descriptionAvaliacao}
                          onChange={(e) => {
                            setDesafio9((prev) => {
                              const desafio = { ...prev };
                              desafio.descriptionAvaliacao = e.target.value;
                              return desafio;
                            });
                          }}
                        />
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio10.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio10.subTitle}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio10.subTitle2}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio10((prev) => {
                              const desafio = { ...prev };
                              desafio.response = e.target.value;
                              desafio.descriptionAvaliacao = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.desafio10.yes}
                              control={<Radio />}
                              label={translator.framework.create.desafio10.yes}
                            />
                            <FormControlLabel
                              value={translator.framework.create.desafio10.no}
                              control={<Radio />}
                              label={translator.framework.create.desafio10.no}
                            />
                          </div>
                        </RadioGroup>

                        {desafio10.response ===
                          translator.framework.create.desafio10.yes && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio10
                                    .placeholder
                                }
                              </InputLabel>
                              <Input
                                value={desafio10.descriptionAvaliacao}
                                onChange={(e) => {
                                  setDesafio10((prev) => {
                                    const desafio = { ...prev };
                                    desafio.descriptionAvaliacao =
                                      e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio10.topico1.title}
                        </b>
                      </span>
                      <p />
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio10.topico1
                              .subTitle
                          }
                        </b>
                      </span>
                      <p />

                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio10((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.response = e.target.value;
                              desafio.topico1.descriptionAvaliacao = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico1
                                  .yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico1
                                  .yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico1.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico1.no
                              }
                            />
                          </div>
                        </RadioGroup>

                        {desafio10.topico1.response ===
                          translator.framework.create.desafio10.topico1.yes && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio10.topico1
                                    .placeholder
                                }
                              </InputLabel>
                              <Input
                                value={desafio10.topico1.descriptionAvaliacao}
                                onChange={(e) => {
                                  setDesafio10((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico1.descriptionAvaliacao =
                                      e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio10.topico2.title}
                        </b>
                      </span>
                      <p />
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio10.topico2
                              .subTitle
                          }
                        </b>
                      </span>
                      <p />

                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio10((prev) => {
                              const desafio = { ...prev };
                              desafio.topico2.response = e.target.value;
                              desafio.topico2.descriptionAvaliacao = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico2
                                  .yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico2
                                  .yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico2.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico2.no
                              }
                            />
                          </div>
                        </RadioGroup>

                        {desafio10.topico2.response ===
                          translator.framework.create.desafio10.topico2.yes && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio10.topico2
                                    .placeholder
                                }
                              </InputLabel>
                              <Input
                                value={desafio10.topico2.descriptionAvaliacao}
                                onChange={(e) => {
                                  setDesafio10((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico2.descriptionAvaliacao =
                                      e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio10.topico3.title}
                        </b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio10((prev) => {
                              const desafio = { ...prev };
                              desafio.topico3.response = e.target.value;
                              desafio.topico3.descriptionAvaliacao = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico3
                                  .yes
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico3
                                  .yes
                              }
                            />
                            <FormControlLabel
                              value={
                                translator.framework.create.desafio10.topico3.no
                              }
                              control={<Radio />}
                              label={
                                translator.framework.create.desafio10.topico3.no
                              }
                            />
                          </div>
                        </RadioGroup>

                        {desafio10.topico3.response ===
                          translator.framework.create.desafio10.topico3.yes && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio10.topico3
                                    .placeholder
                                }
                              </InputLabel>
                              <Input
                                value={desafio10.topico3.descriptionAvaliacao}
                                onChange={(e) => {
                                  setDesafio10((prev) => {
                                    const desafio = { ...prev };
                                    desafio.topico3.descriptionAvaliacao =
                                      e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio11.title}</b>
                      </span>
                      <p />
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio11((prev) => {
                              const desafio = { ...prev };
                              desafio.response = e.target.value;
                              desafio.description = "";
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio11
                                    .response1
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio11
                                    .response1
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio11
                                    .response2
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio11
                                    .response2
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio11
                                    .response3
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio11
                                    .response3
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio11
                                    .response4
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio11
                                    .response4
                                }
                              />
                            </Grid>
                          </div>
                        </RadioGroup>
                        {desafio11.response ===
                          translator.framework.create.desafio11.response2 && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio11
                                    .placeholderGrupo
                                }
                              </InputLabel>
                              <Input
                                value={desafio11.description}
                                onChange={(e) => {
                                  setDesafio11((prev) => {
                                    const desafio = { ...prev };
                                    desafio.description = e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                        {desafio11.response ===
                          translator.framework.create.desafio11.response4 && (
                          <Paper className={classes.paper}>
                            <FormControl fullWidth>
                              <InputLabel htmlFor="standard-adornment-amount">
                                {
                                  translator.framework.create.desafio11
                                    .placeholderAtividade
                                }
                              </InputLabel>
                              <Input
                                value={desafio11.description}
                                onChange={(e) => {
                                  setDesafio11((prev) => {
                                    const desafio = { ...prev };
                                    desafio.description = e.target.value;
                                    return desafio;
                                  });
                                }}
                              />
                            </FormControl>
                          </Paper>
                        )}
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>{translator.framework.create.desafio12.title}</b>
                      </span>
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio12.subTitle}</b>
                      </span>
                      <p />
                      <p />
                      <span>
                        <b>{translator.framework.create.desafio12.subTitle2}</b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio12((prev) => {
                              const desafio = { ...prev };
                              desafio.response = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <FormControlLabel
                              value={translator.framework.create.desafio12.yes}
                              control={<Radio />}
                              label={translator.framework.create.desafio12.yes}
                            />

                            <FormControlLabel
                              value={translator.framework.create.desafio12.no}
                              control={<Radio />}
                              label={translator.framework.create.desafio12.no}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paper className={classes.paper}>
                      <span>
                        <b>
                          {translator.framework.create.desafio12.topico1.title}
                        </b>
                      </span>
                      <p />
                      <span>
                        <b>
                          {
                            translator.framework.create.desafio12.topico1
                              .subTitle
                          }
                        </b>
                      </span>
                      <p />
                      <FormControl fullWidth>
                        <RadioGroup
                          onChange={(e) => {
                            setDesafio12((prev) => {
                              const desafio = { ...prev };
                              desafio.topico1.response = e.target.value;
                              return desafio;
                            });
                          }}
                          aria-label="gender"
                        >
                          <div>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio12.topico1
                                    .response1
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio12.topico1
                                    .response1
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio12.topico1
                                    .response2
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio12.topico1
                                    .response2
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                value={
                                  translator.framework.create.desafio12.topico1
                                    .response3
                                }
                                control={<Radio />}
                                label={
                                  translator.framework.create.desafio12.topico1
                                    .response3
                                }
                              />
                            </Grid>
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </FormControl>
          </span>
        </Container>
      </div>
    </>
  );
}

export default Create;
