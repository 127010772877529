import React from "react";
import {
  makeStyles,
  FormControl,
  Grid,
  Paper,
  InputLabel,
  Input,
} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    color: "#57acb0",
    flexGrow: 2,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitle: {
    textAlign: "justify",
  },
  share: {
    margin: "30px 0px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
}));

function App(props) {
  const classes = useStyles();
  const {
    setTopics,
    setLevel,
  } = props;
  return (
    <div className={classes.body}>
      <h1 className={classes.title}>Paths: Create a Course Map</h1>
      <span className={classes.subTitle}>
        This lesson plan template was inspired by Joseph Campbell’s Hero's
        Journey, and Heroine's Journey by Murdock, as well as researches in
        fields of narrative, self-regulation of learning, motivational theories,
        flipped classroom and gamification. Also hade studies about gender
        equality and STEM . It will help you to map a unit of instruction to the
        twelve stages of Heroine's Learning Journey, establishing students as
        the drivers of their own unique process through the module or unit of
        instruction. Instructions: Fill in each box, considering how the
        learning content, assignments and intended learning outcomes might be
        woven into a narrative structure that establishes the student as an epic
        hero completing a journey through the course content.
      </span>
      <hr className={classes.share} />
      <FormControl>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>
                What topics do you plan to cover throughout the course?
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setTopics(event.target.value)} id="roles" name="stages-2-1" />
              </FormControl>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <span>
                Upon completion of this module or unit of instruction, what
                would you like students to be able to independently use their
                learning to do?
              </span>
              <p />
              <FormControl fullWidth>
                <InputLabel htmlFor="standard-adornment-amount">
                  Texto de resposta longa
                </InputLabel>
                <Input onChange={(event) => setLevel(event.target.value)} id="roles" name="stages-2-1" />
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </FormControl>
    </div>
  );
}

export default App;
