import React, { useContext } from "react";
import Cards from "./Cards";
import About from "./About";
import Capa from "./Capa";
import Contato from "./Contato";
import Objetivo from "./Objetivo";
import Ods4 from "./Ods4";
import Ods5 from "./Ods5";
import Ods8 from "./Ods8";
import Parceiros from "./Parceiros";
import DataContext from "../../utils/DataContext";
import locale from "../../utils/Locale";

function Main() {
  const language = useContext(DataContext);
  const translator = locale[language];

  return (
    <>
      <Capa translator={translator}/>
      <Cards translator={translator}/>
      <About translator={translator} />
      <Parceiros />
      <Contato translator={translator}/>
      <Objetivo />
      <Ods4 translator={translator}/>
      <Ods5 translator={translator}/>
      <Ods8 translator={translator}/>
    </>
  );
}

export default Main;
