import React, { useState, useEffect } from "react";
import {
  Container,
  makeStyles,
  FormControl,
  Grid,
  Paper,
  InputLabel,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
  CardContent,
  Typography,
  Link
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  body: {
    color: "#57acb0",
    flexGrow: 2,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  title: {
    fontWeight: "bold",
    textAlign: "left",
  },
  subTitle: {
    textAlign: "justify",
  },
  share: {
    margin: "30px 0px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
  media: {
    width: "200",
    maxHeight: 400,
    margin: "30px 0px",
  },
}));

function Desafio1({ course, translator }) {
  const classes = useStyles();
  const [journey, setJorney] = useState(null);

  const ApiJourney = "http://heroicjourneys.life:5000/journey/3";

  const loadJourney = async () => {
    const response = await fetch(ApiJourney, {
      method: "GET",
    });
    const data = await response.json();
    setJorney(data);
  };

  useEffect(() => {
    loadJourney();
  }, []);

  useEffect(() => {
  }, [journey, course]);
  return (
    <Container className={classes.body} fullWidth>
      {journey && (
        <>
          <h1 className={classes.title}>{translator.framework.show.title}</h1>
          <hr className={classes.share} />
          <FormControl>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico1.title}</h3>
                  <span>
                    {translator.framework.show.stage1.topico1.description}
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[0].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <span>
                    {translator.framework.show.stage1.topico1.question1}
                  </span>
                  <p />
                  <h5>
                    {
                      translator.framework.show.stage1.topico1
                        .descriptionQuestion1
                    }
                  </h5>
                  <p />
                  <FormControl fullWidth>
                    <RadioGroup aria-label="gender" name="gender1">
                      <FormControlLabel
                        checked={true}
                        control={<Radio />}
                        label={course.response19.response}
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.duration}</h3>
                  <span>
                    {translator.framework.show.steps.descriptionDuration}
                  </span>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.step1}</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    <h3>{translator.framework.show.stage1.topico2.title}</h3>
                  </h3>
                  <span>
                    {translator.framework.show.stage1.topico2.description}
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[1].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <span>
                    <h3>
                      {translator.framework.show.stage1.topico2.question1}
                    </h3>
                  </span>
                  <p />
                  <h5>
                    {
                      translator.framework.show.stage1.topico2
                        .descriptionQuestion1
                    }
                  </h5>
                  <p />
                  <FormControl fullWidth>
                    <RadioGroup aria-label="gender" name="gender1">
                      <FormControlLabel
                        checked={true}
                        control={<Radio />}
                        label={course.response20.topico3}
                      />
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <span>
                    <h3>
                      {translator.framework.show.stage1.topico2.question2}
                    </h3>
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <RadioGroup aria-label="gender" name="gender1">
                      {course.response20.topico4.apostila && (
                        <FormControlLabel
                          checked={course.response20.topico4.apostila}
                          control={<Radio />}
                          label="Apostila"
                        />
                      )}
                      {course.response20.topico4.roteiro && (
                        <FormControlLabel
                          checked={course.response20.topico4.roteiro}
                          control={<Radio />}
                          label="Roteiro"
                        />
                      )}
                      {course.response20.topico4.dicas && (
                        <FormControlLabel
                          checked={course.response20.topico4.dicas}
                          control={<Radio />}
                          label="Dicas"
                        />
                      )}
                      {!course.response20.topico4.dicas &&
                        !course.response20.topico4.roteiro &&
                        !course.response20.topico4.dicas && (
                          <FormControlLabel
                            checked={course.response20.topico4.dicas}
                            control={<Radio />}
                            label="Não"
                          />
                        )}
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico2.question3}</h3>
                  <span>
                    {
                      translator.framework.show.stage1.topico2
                        .descriptionQuestion3
                    }
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <RadioGroup aria-label="gender" name="gender1">
                      {course.response20.topico6.quiz && (
                        <FormControlLabel
                          checked={course.response20.topico6.quiz}
                          control={<Radio />}
                          label="Quiz"
                        />
                      )}
                      {course.response20.topico4.leitura && (
                        <FormControlLabel
                          checked={course.response20.topico4.leitura}
                          control={<Radio />}
                          label="Leitura"
                        />
                      )}
                      {course.response20.topico4.exercicio && (
                        <FormControlLabel
                          checked={course.response20.topico4.exercicio}
                          control={<Radio />}
                          label="Exercício"
                        />
                      )}
                      {course.response20.topico4.outros && (
                        <FormControlLabel
                          checked={course.response20.topico4.outros}
                          control={<Radio />}
                          label={course.response20.topico4.description}
                        />
                      )}
                      {!course.response20.topico4.default && (
                        <FormControlLabel
                          checked={course.response20.topico4.default}
                          control={<Radio />}
                          label="Nenhuma das opções"
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.duration2}</h3>
                  <span>
                    {translator.framework.show.steps.descriptionDuration2}
                  </span>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.step2}</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico3.title}</h3>
                  <span>
                    {translator.framework.show.stage1.topico3.description}
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[2].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico3.question1}</h3>
                  <span>
                    {
                      translator.framework.show.stage1.topico3
                        .descriptionQuestion1
                    }
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <RadioGroup aria-label="gender" name="gender1">
                      {course.response21.response === "Não" && (
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label={course.response21.response3}
                        />
                      )}
                      {course.response21.response === "No" && (
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label={course.response21.response3}
                        />
                      )}
                      {course.response21.response === "Sim" && (
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label={course.response21.response2}
                        />
                      )}
                      {course.response21.response === "Yes" && (
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label={course.response21.response2}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.duration3}</h3>
                  <span>
                    {translator.framework.show.steps.descriptionDuration3}
                  </span>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.step3}</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico4.title}</h3>
                  <span>
                    {translator.framework.show.stage1.topico4.description}
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[3].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico4.question1}</h3>
                  <span>
                    {
                      translator.framework.show.stage1.topico4
                        .descriptionQuestion1
                    }
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <h3>{translator.framework.show.stage1.topico4.title1}</h3>
                    {course.response22.topico1.podcats === "Não" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.podcats === "No" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.podcats === "Sim" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.podcatsVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                    {course.response22.topico1.podcats === "Yes" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.podcatsVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <h3>{translator.framework.show.stage1.topico4.title2}</h3>
                    {course.response22.topico1.tutoriais === "Não" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.tutoriais === "No" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.tutoriais === "Sim" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.tutoriaisVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                    {course.response22.topico1.podcats === "Yes" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.tutoriaisVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <h3>{translator.framework.show.stage1.topico4.title3}</h3>
                    {course.response22.topico1.manuais === "Não" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.manuais === "No" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.manuais === "Sim" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.manuaisVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                    {course.response22.topico1.podcats === "Yes" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.manuaisVideos.map(
                          (video, key) => (
                            <Card className={classes.root} variant="outlined">
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  color="textSecondary"
                                  gutterBottom
                                >
                                  {video.nome}
                                </Typography>
                                <Typography variant="body2" component="p">
                                <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                                </Typography>
                              </CardContent>
                            </Card>
                          )
                        )}
                      </Grid>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <h3>{translator.framework.show.stage1.topico4.title4}</h3>
                    {course.response22.topico1.isVideo === "Não" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.isVideo === "No" && (
                      <RadioGroup aria-label="gender" name="gender1">
                        <FormControlLabel
                          checked={true}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    )}
                    {course.response22.topico1.isVideo === "Sim" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.videos.map((video, key) => (
                          <Card className={classes.root} variant="outlined">
                            <CardContent>
                              <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                              >
                                {video.nome}
                              </Typography>
                              <Typography variant="body2" component="p">
                                <Link
                                  variant="body2"
                                  href={video.url}
                                  target="_blank"
                                >
                                  {video.url}
                                </Link>
                              </Typography>
                            </CardContent>
                          </Card>
                        ))}
                      </Grid>
                    )}
                    {course.response22.topico1.isVideo === "Yes" && (
                      <Grid item xs={12} sm={12}>
                        {course.response22.topico1.videos.map((video, key) => (
                          <Card className={classes.root} variant="outlined">
                            <CardContent>
                              <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                              >
                                {video.nome}
                              </Typography>
                              <Typography variant="body2" component="p">
                              <Link
                                  component="button"
                                  variant="body2"
                                  href={video.url}
                                >
                                  {video.url}
                                </Link>
                              </Typography>
                            </CardContent>
                          </Card>
                        ))}
                      </Grid>
                    )}
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.stage1.topico4.question2}</h3>
                  <span>
                    {
                      translator.framework.show.stage1.topico4
                        .descriptionQuestion2
                    }
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>{translator.framework.show.steps.duration4}</h3>
                  <span>
                    {translator.framework.show.steps.descriptionDuration4}
                  </span>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>Facing a Storming video - step 4 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE I - EMPOWERMENT -STEM Learners can say no: Progress -
                    Stage 1, Step 5
                  </h3>
                  <span>
                    What happens? - Now(Learner), probably lost your fear about
                    contents of the exact areas, gained confidence through your
                    activities and projects, and it may be possible that have
                    comments on forums, specific collaboration tasks to finally
                    say, “STEM learners can say no”. When someone tells you that
                    you have no ability to program, perform calculations,
                    analyze data, or design tools and applications, you say ”No,
                    i can do it!”.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[4].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    External help is available to students (Tutor; Teacher;
                    Allies). This help can be with human participation or
                    informed through tips, suggestions, videos or previously
                    programmed to occur automatically for (Learner); May arise
                    videos or interviews with girls from companies or
                    initiatives(Tutor; Teacher; Allies), to (Learner)get to know
                    the technology work environments (virtual or face-to-face),
                    and examples of applications about related course.
                  </h3>
                  <span>
                    Please write down the list helps containing your description
                    and time. Do not forget to also describe any other learning
                    activities that will be provided in this step
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>STEM Learners can say no video - step 5 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE II - GROWTH -The Light of Knowledge: Progress - Stage
                    2, Step 6
                  </h3>
                  <span>
                    What happens? - Considered as one of the most important
                    stages, here (Learner)need to understand what found in this
                    new world, yes, (Learner) found a new world. As of right
                    now, programming codes, mathematical formulas, and theories
                    that (Learner)have so far, will allow (Learner) to create
                    new projects and activities. In this new world, in the area
                    of Science, Technology, Engineering e Mathematics, there are
                    other students, friends, work presentations, workshops, and
                    new opportunities(Allies). here (Learner)need to understand
                    what found in this new world, yes, (Learner) found a new
                    world. As of right now, programming codes, mathematical
                    formulas, and theories that (Learner)have so far, will allow
                    (Learner) to create new projects and activities. In this new
                    world, in the area of Science, Technology, Engineering e
                    Mathematics, there are other students, friends, work
                    presentations, workshops, and new opportunities(Allies).
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[5].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    In this step, content related to the different areas of STEM
                    can be presented to (Learner) by (Tutor; Teacher; Allies) as
                    practical activities. For example, data manipulation,
                    statistics, hands-on activity, presentations of real
                    applications. Assessments made by (Tutor; Teacher) or
                    previously programmed by the course, can happen.
                  </h3>
                  <span>
                    Please write below the list practical activities, your
                    description and time. Do not forget to also describe any
                    test or assessment provided by the course that will be
                    provided in this step
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>The Light of Knowledge video - step 6 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE II - GROWTH - The Meeting with the Lady of Keys:
                    Progress - Stage 2, Step 7
                  </h3>
                  <span>
                    What happens? - This step will require (Learner) to put into
                    practice everything they learned so far. (Learner)ran
                    through the basics of matter and feel strong enough to
                    continue your studies. (Learner) also commit mathematical
                    formulas from data queries or other important theories in
                    the fields of STEM.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[6].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    There may be presentations by (Learner), group tasks
                    (Learner; Allies) or projects can occur. If the course
                    requires building projects, now is the time to development a
                    real application.
                  </h3>
                  <span>
                    Please write below the activities, your description and
                    time.
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    The Meeting with the Lady of Keys video - step 7 of 12
                  </h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE II - GROWTH - The Encryption of codes: Progress -
                    Stage 2, Step 8
                  </h3>
                  <span>
                    What happens? - So far (Learner) who have learned a lot, are
                    ready. What was the last thing that (Learner) get? (Learner)
                    acquired self-confidence, self-regulation inthe learning
                    process, (Learner) also lost the fear of programming or
                    performing math-ematical formulas, for example, this way
                    several applications, simulations, exercises,developed by
                    (Learner) are available on the education platforms of the
                    course, or even in public repositories, social networks, and
                    (Learner) personal computers. The time for (Learner) big
                    challenge is coming, (Learner) needs to get ready, get
                    organized and prepared for the next step.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[7].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    Simulations, exercises, evaluations and reflections on
                    content occur at this step by (Learner; Allies). In this
                    step, (Learner) can share their projects on socialnetworks,
                    through videos or code repository. The final project
                    development it mayhappens by (Learner). Hands On from
                    (Tutor; Teacher; Allies) can also happens to(Learner)
                    develop your solutions.
                  </h3>
                  <span>
                    Please write below the activities, your description and
                    time.
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>The Encryption of codes video - step 8 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE II - GROWTH - Numbers are passwords: Progress - Stage
                    2, Step 9
                  </h3>
                  <span>
                    What happens? - (Learner) are about to face your first big
                    challenge or project,but (Learner) already have some
                    examples that will facilitate the final clash of deliveries
                    in this course. So (Learner) feel confident because they are
                    prepared, they need to deliver your project, carry out a
                    test, carry out an assessment or practical work, inorder to
                    obtain the evaluation of (Tutor, Teacher) and the community
                    around them.Let’s go ahead, (Learner) can overcome this
                    great challenge.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[8].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    (Learner) need to perform project presentations, pitches,
                    assignments, exercises and final assessments. There may be
                    evaluations by the students themselves.
                  </h3>
                  <span>
                    Please write below the activities, your description and
                    time.
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>Numbers are passwords video - step 9 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE II - GROWTH - Self-Regulated Learning: Progress -
                    Stage 2, Step 10
                  </h3>
                  <span>
                    What happens? - (Learner) who came face to face with the
                    great challenges and enemies, now have the results of all
                    efforts. It is time to have access to final scores and
                    evaluations of the course. Also, time to be sure, that with
                    all their practice and new skills acquired (Learner) can
                    continue learning STEM.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={journey.stages.length > 0 && journey.stages[9].image}
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    (Learner) receives reports, results of final evaluations and
                    final instructions consolidated in 10 main tips for
                    (Learner) to continue their studies in a satisfactory
                    manner.
                  </h3>
                  <span>
                    Please write below what reports, results or final
                    evaluations the course have and how is show to students.
                    Don't forget to send the 10 main tips building by Heroines
                    Learning Journey
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>Self-Regulated Learningvideo - step 10 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE III - RETURN - Reboot The System: Progress - Stage 3,
                    Step 11
                  </h3>
                  <span>
                    What happens? - In this step (Learner) get to know how they
                    can become a true heroine. This step offers to (Learner) the
                    possibility of earning (Learner Heroine)status.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={
                        journey.stages.length > 0 && journey.stages[10].image
                      }
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    This course has the option to generate a code for
                    participating in a multi-platform instant messaging
                    application, text messages, images, videos and documents,
                    such as telegram, discord? participation in these
                    applications is intended to make the student keep studying
                    in STEM with other students who have the same interest, is
                    specific to each course and can only be used for subjects
                    related to the topic, as well as providing help, tips and
                    promoting the relationship between students.
                  </h3>
                  <span>
                    If this course has this option, please describe how the
                    group will be organized, which application will be used and
                    which roles will participate
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>Reboot The System video - step 11 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    STAGE III - RETURN -Becoming a STEM Heroine: Progress -
                    Stage 3, Step 12
                  </h3>
                  <span>
                    What happens? - So, what are the latest challenges? How did
                    (Learner) transform? Where will other girls share their
                    knowledge? What will happens to (Learner)now? How was the
                    individual feedback they had? Only true (Learner Heroine)
                    are able to accomplish this step.
                  </span>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src={
                        journey.stages.length > 0 && journey.stages[11].image
                      }
                      alt="Img"
                    />
                  </center>
                  <p />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>
                    As a result of this journey, the (Learner Heroine) receives
                    a certificate or a final report that proves your bravery and
                    experience. But for that to happens and to maintain their
                    (Learner Heroine) status, they will still need to help
                    another(Learner) out to start her studies by conducting a
                    mentoring during the next course offered.
                  </h3>
                  <span>
                    If this course has this option, please describe how is the
                    certificate or report that students receive, as well as the
                    invitation for Learner Heroine to join next edition of the
                    course.
                  </span>
                  <p />
                  <FormControl fullWidth>
                    <InputLabel htmlFor="standard-adornment-amount">
                      Texto de resposta longa
                    </InputLabel>
                    <Input id="roles" name="stages-3-1" />
                  </FormControl>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Paper className={classes.paper}>
                  <h3>Becoming a STEM Heroine video - step 12 of 12</h3>
                  <p />
                  <center>
                    <img
                      className={classes.media}
                      src="../images/img_video.png"
                      alt="Img"
                    />
                  </center>
                </Paper>
              </Grid>
            </Grid>
          </FormControl>
        </>
      )}
    </Container>
  );
}
export default Desafio1;
