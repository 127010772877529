import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
} from "@material-ui/core";
import Desafio1 from "./Desafio1"

const useStyles = makeStyles((theme) => ({
  body: {
    paddingTop: "40px",
    minHeight: "100vh",
  },
  title: {
    fontSize: "30px",
    padding: "20px",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  description: {
    fontSize: 18,
    textAlign: "justify",
    width: "80%",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "justify",
    color: theme.palette.text.secondary,
  },
}));

function Show({ courses, setView, translator, user }) {
  const classes = useStyles();
  const [course, setCourse] = useState(courses !== undefined ? courses : []);
  

  useEffect(() => {
    if (courses !== undefined) {
      sessionStorage.setItem("course", JSON.stringify(courses));
    }
    if (sessionStorage.getItem("course")) {
      let local = JSON.parse(sessionStorage.getItem("course"));
      setCourse(local);
    } else {
      setView(0);
    }
  }, [courses, setView]);

  return (
    <Container className={classes.body} fullWidth>
      <Desafio1 course={course} translator={translator} />
    </Container>
  );
}
export default Show;
