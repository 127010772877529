import React from "react";
import { Box, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    textAlign: "center",
    backgroundColor: "#ff3a21",
    height: 200,
    display: "flex",
    justifyContent: "center",
  },
  osd: {},
  imgOne: {
    width: 120,
    marginTop: "30%",
    paddingRight:5
  },
  imgTwo: {
    marginTop: "12%",
    width: 400,
  },
  textOds: {
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
    textAlign: "justify",
    color: "#ffffff",
    fontSize: 14,
    width: "30%",
    position: "relative",
  },
  childrenText: {
    position: "absolute",
    top: "30%",
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
}));

function Ods(props) {
  const classes = useStyles();
  const { translator } = props;
  return (
    <Box className={classes.body}>
      <Box>
        <img
          className={classes.imgOne}
          src="images/icon-ods5.png"
          alt=""
        />
      </Box>
      <Box>
        <img
          className={classes.imgTwo}
          src="images/ods5.png"
          alt=""
        />
      </Box>
      <Box className={classes.textOds}>
        <span className={classes.childrenText}>
          <span>
            <span>
              <strong>5.5</strong>
            </span>{" "}
            {translator.main.ods5.descricao1}
          </span>
        </span>
      </Box>
    </Box>
  );
}

export default Ods;
