import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const useStyles = makeStyles({
  root: {},
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    width: '100%',
    height: 420,
    transitionDuration: '0.5s',
   /* "&:hover": {
      transform: "rotateY(180deg)",
    },*/
  },
  sw: {},
  cardAc: {},
});

function ItemCar(props) {
  const classes = useStyles();
  const { name, image, translator } = props;
  const [checked, setchecked] = useState(false);
  const [descricao, setDescricao] = useState("");

  const handleChange = (name) => (event) => {
    setchecked(event.target.checked);
  };

  const isImagem = (caminho) => {
    const checkImg = caminho ? caminho : "../images/logo_.png";
    return checkImg;
  };

  useEffect(() => {
    const { url } = props;
    async function fetchData() {
      await fetch(url, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          const { description } = data;
          setDescricao(description);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, [props]);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <img className={classes.media} src={isImagem(image)} alt={name} />
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            {name}
          </Typography>
          {checked && (
            <Typography variant="body2" color="textSecondary" component="p">
              {descricao}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardAc}>
        <FormControlLabel
          className={classes.sw}
          control={
            <Switch
              checked={checked}
              onChange={handleChange()}
              value="checked"
              color="primary"
            />
          }
          label={translator.main.cards.checked}
        />
      </CardActions>
    </Card>
  );
}

export default ItemCar;
