import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DataContext from "../../utils/DataContext";
import locale from "../../utils/Locale";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#721e99",
    color: "#57acb0",
    textAlign: "center",
    padding: 20,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
}));

function Footer() {
  const classes = useStyles();
  const language = useContext(DataContext);
  const translator = locale[language];
  return (
    <footer className={classes.footer}>
      &copy;Copyright 2020-2021 {translator.nav.title}
    </footer>
  );
}

export default Footer;
