import React, { useState, useEffect } from "react";
import { Button, makeStyles, Container, Fab } from "@material-ui/core";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import FrameworksStage1 from "./FrameworksStage1";
import FrameworksStage2 from "./FrameworksStage2";
import FrameworksStage3 from "./FrameworksStage3";
import SaveIcon from "@material-ui/icons/Save";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  body: {
    flexGrow: 2,
    paddingTop: 130,
    paddingBottom: 100,
    fontFamily:
      "courier new,courier-ps-w01,courier-ps-w02,courier-ps-w10,monospace",
  },
  groupBtn: {
    position: "absolute",
    right: 20,
    top: 120,
  },
  btnNext: {
    margin: 20,
  },
  title: {
    color: "#721e99",
    fontSize: 20,
  },
  btnSave: {
    position: "absolute",
    right: 40,
    top: 80,
    backgroundColor: "green",
  },
  alert: {
    position: "fixed",
    width: 300,
    bottom: 120,
    right: 20,
  },
}));

function App() {
  const classes = useStyles();
  const [stages, setStages] = useState(1);
  const [alert, setAlert] = useState();
  const [view, setView] = useState(0);

  const [name, setName] = useState("");
  const [edicao, setEdicao] = useState("");
  const [paper, setPaper] = useState("");
  const [couserDesigner, setCouserDesigner] = useState("");
  const [learner, setLeaner] = useState("");
  const [leanerHeroine, setLeanerHeroine] = useState("");
  const [tutor, setTutor] = useState("");
  const [teacher, setTeacher] = useState("");
  const [allies, setAllies] = useState("");
  const [motherGoddess, setMotherGoddess] = useState("");
  const [athena, setAthena] = useState("");

  const [topics, setTopics] = useState("");
  const [level, setLevel] = useState("");

  const [response1, setResponse1] = useState("");
  const [response2, setResponse2] = useState("");
  const [response3, setResponse3] = useState("");
  const [response4, setResponse4] = useState("");
  const [response5, setResponse5] = useState("");
  const [response6, setResponse6] = useState("");
  const [response7, setResponse7] = useState("");
  const [response8, setResponse8] = useState("");
  const [response9, setResponse9] = useState("");
  const [response10, setResponse10] = useState("");
  const [response11, setResponse11] = useState("");
  const [response12, setResponse12] = useState("");
  const [response13, setResponse13] = useState("");
  const [response14, setResponse14] = useState("");
  const [response15, setResponse15] = useState("");
  const [response16, setResponse16] = useState("");
  const [response17, setResponse17] = useState("");
  const [response18, setResponse18] = useState("");
  const [response19, setResponse19] = useState("");
  const [response20, setResponse20] = useState("");
  const [response21, setResponse21] = useState("");
  const [response22, setResponse22] = useState("");
  const [response23, setResponse23] = useState("");
  const [response24, setResponse24] = useState("");
  const [response25, setResponse25] = useState("");
  const [response26, setResponse26] = useState("");
  const [response27, setResponse27] = useState("");

  const [journey, setJourney] = useState([]);
  
  const user = localStorage.length
  ? JSON.parse(localStorage.getItem("currentUser"))
  : null;

  const id_journey = 3; //Journey is Luis

  const ApiJourney = "http://heroicjourneys.life:5000/journey";

  const changeStages = (type) => (event) => {
    type ? setStages(stages + 1) : setStages(stages - 1);
  };

  const url = "https://filipecassimiro.com.br/api/courses";

  const saveCourse = async (event) => {
    const data = {
      userId: user.id,
      name,
      edicao,
      paper,
      couserDesigner,
      learner,
      leanerHeroine,
      tutor,
      teacher,
      allies,
      motherGoddess,
      athena,
      topics,
      level,
      response1,
      response2,
      response3,
      response4,
      response5,
      response6,
      response7,
      response8,
      response9,
      response10,
      response11,
      response12,
      response13,
      response14,
      response15,
      response16,
      response17,
      response18,
      response19,
      response20,
      response21,
      response22,
      response23,
      response24,
      response25,
      response26,
      response27,
    };
    await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        setAlert(data);
      })
      .catch((error) => {
        setAlert(error);
      });
  };

  useEffect(() => {
      if (alert === true) {
        setView(1);
      } else if(alert === false) {
        setView(2);
      }
  }, [alert]);

  useEffect(() => {
    if(view !== 0){
      setTimeout(() => {
        setView(0);
      }, 4000);
    }
}, [view]);


  useEffect(() => {
   
    if (stages > 3) {
      setStages(3);
    }
    if (stages < 1) {
      setStages(1);
    }
  }, [stages]);

  useEffect(() => {
    document.title = "Heroic Journeys";
    async function fetchData() {
      setJourney([]);
      await fetch(`${ApiJourney}/${id_journey}`, {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          setJourney(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    fetchData();
  }, []);

  return (
    <div className={classes.body}>
      <Container fixed>
        <div className={classes.groupBtn}>
          <Fab
            color="primary"
            className={classes.btnNext}
            onClick={changeStages(false)}
            aria-label="add"
          >
            <SkipPreviousIcon />
          </Fab>
          {stages} of 3 Stages
          <Fab
            color="primary"
            className={classes.btnNext}
            onClick={changeStages(true)}
            aria-label="add"
          >
            <SkipNextIcon />
          </Fab>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.btnSave}
          startIcon={<SaveIcon />}
          onClick={saveCourse}
        >
          Save
        </Button>

        <form noValidate autoComplete="off">
          <span className={classes.title}>
            Welcome to the journey <strong>{journey.name}</strong>
          </span>
          {stages === 1 && (
            <FrameworksStage1
              setName={setName}
              setEdicao={setEdicao}
              setPaper={setPaper}
              setCouserDesigner={setCouserDesigner}
              setLeaner={setLeaner}
              setLeanerHeroine={setLeanerHeroine}
              setTutor={setTutor}
              setTeacher={setTeacher}
              setAllies={setAllies}
              setMotherGoddess={setMotherGoddess}
              setAthena={setAthena}
            />
          )}
          {stages === 2 && (
            <FrameworksStage2 setTopic={setTopics} setLevel={setLevel} />
          )}
          {stages === 3 && (
            <FrameworksStage3
              setResponse1={setResponse1}
              setResponse2={setResponse2}
              setResponse3={setResponse3}
              setResponse4={setResponse4}
              setResponse5={setResponse5}
              setResponse6={setResponse6}
              setResponse7={setResponse7}
              setResponse8={setResponse8}
              setResponse9={setResponse9}
              setResponse10={setResponse10}
              setResponse11={setResponse11}
              setResponse12={setResponse12}
              setResponse13={setResponse13}
              setResponse14={setResponse14}
              setResponse15={setResponse15}
              setResponse16={setResponse16}
              setResponse17={setResponse17}
              setResponse18={setResponse18}
              setResponse19={setResponse19}
              setResponse20={setResponse20}
              setResponse21={setResponse21}
              setResponse22={setResponse22}
              setResponse23={setResponse23}
              setResponse24={setResponse24}
              setResponse25={setResponse25}
              setResponse26={setResponse26}
              setResponse27={setResponse27}
              data={JSON.stringify(journey)}
            />
          )}
        </form>
      </Container>
      {view === 1 && (
        <Alert
          severity="success"
          className={classes.alert}
          onClose={() => {
            setView(0);
          }}
        >
          <AlertTitle>Success</AlertTitle>
          Course successfully registered!
        </Alert>
      )}

      {view === 2 && (
        <Alert
          severity="error"
          className={classes.alert}
          onClose={() => {
            setView(0);
          }}
        >
          <AlertTitle>Error</AlertTitle>
          Error when registering the course!
        </Alert>
      )}
    </div>
  );
}

export default App;
