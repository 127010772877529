import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

const RadioGroupCreateMaeDeusa = ({ translator, setMaeDeusa }) => {
  const changeResponse = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setMaeDeusa((prev) => {
      const list = prev;
      list.forEach((element) => {
        if (name === "etapa1") {
          element.etapa1 = value;
        } else {
          element.etapa2 = value;
        }
      });
      return list;
    });
  };

  return (
    <>
      <FormControl fullWidth>
        <p>
          <span>
            <b>{translator.framework.create.maeDeusa.title2}</b>
          </span>
        </p>
        <RadioGroup onChange={changeResponse} aria-label="gender" name="etapa1">
          <div>
            <FormControlLabel
              value={translator.framework.create.maeDeusa.etapa1Yes}
              control={<Radio />}
              label={translator.framework.create.maeDeusa.etapa1Yes}
            />
            <FormControlLabel
              value={translator.framework.create.maeDeusa.etapa1No}
              control={<Radio />}
              label={translator.framework.create.maeDeusa.etapa1No}
            />
          </div>
        </RadioGroup>
      </FormControl>
      <FormControl fullWidth>
        <p>
          <span>
            <b>{translator.framework.create.maeDeusa.title3}</b>
          </span>
        </p>
        <RadioGroup onChange={changeResponse} aria-label="gender" name="etapa2">
          <div>
            <FormControlLabel
              value={translator.framework.create.maeDeusa.etapa2Yes}
              control={<Radio />}
              label={translator.framework.create.maeDeusa.etapa2Yes}
            />
            <FormControlLabel
              value={translator.framework.create.maeDeusa.etapa2No}
              control={<Radio />}
              label={translator.framework.create.maeDeusa.etapa2No}
            />
          </div>
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioGroupCreateMaeDeusa;
