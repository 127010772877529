const CampoFormulario = [
    "response1",
    "response2",
    "response3",
    "response4",
    "response5",
    "response6",
    "response7",
    "response8",
    "response9",
    "response10",
    "response11",
    "response12",
    "response13",
    "response14",
    "response15",
    "response16",
    "response17",
    "response18",
    "response19",
    "response20",
    "response21",
    "response22",
    "response23",
    "response24",
    "response25",
    "response26",
    "response27",
    "response28",
    "response29",
    "response30",
  ];

  export default CampoFormulario